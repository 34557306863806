import { useEffect, useRef } from 'react';

/** Lets you know if a component has unmounted so you can avoid processing after the fact
 * Returns a boolean ref object which has the value `true` if a component has unmounted */
export default function useDidUnmount() {
    const didUnmount = useRef(false);
    useEffect(() => {
        didUnmount.current = false;
        return () => {
            didUnmount.current = true;
        };
    }, []);

    return didUnmount;
}
