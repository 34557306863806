import Rollbar from 'rollbar';

interface Zoho {
    salesiq: {
        floatwindow: {
            visible: (action: 'show' | 'hide') => void;
        };
    };
}

declare global {
    interface Window {
        BACKEND_URL: string;
        MANUFACTURING_UI_URL: string;
        ROLLBAR_ENABLED: boolean;
        ROLLBAR_ACCESS_TOKEN: string;
        ROLLBAR_ENV: string;
        ROLLBAR_INSTANCE: Rollbar | undefined;
        POSTHOG_ENABLED: boolean;
        POSTHOG_API_KEY: string;
        POSTHOG_HOST: string;
        // used for luxon date time conversion
        // set by auth slice restoreSession
        MANUFACTURER_TIMEZONE: string;
        // zoho chat reference = may be undefined?
        ZOHO_API_KEY: string;
        // initialized by useZohoLiveChat
        $zoho?: Zoho;
    }
}
/**
 * Environment variables
 */
export default {
    NODE_ENV: process.env.NODE_ENV,
    API_BASE_URL: window.BACKEND_URL as string,
    INVENTORY_API_BASE_URL: process.env.REACT_APP_INVENTORY_API_BASE_URL as string,
    MSW_ENABLED: process.env.REACT_APP_MSW_ENABLED === 'true',

    get isProd() {
        return this.NODE_ENV === 'production';
    },
    get isDev() {
        return this.NODE_ENV === 'development';
    },
    get isTest() {
        return this.NODE_ENV === 'test';
    },
};
