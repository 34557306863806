import { mergeDeep } from 'utils/helpers';
import z from 'zod';

export const InventoryMeasureCreateSchema = z.object({
    canPurchase: z.boolean(),
    canSell: z.boolean(),
    canConsume: z.boolean(),
    inventoryId: z.string(),
    name: z.string(),
    unitOfMeasure: z.string(),
    value: z.number(),
});

export class InventoryMeasureCreateFactory {
    static create(data: Partial<InventoryMeasureCreate>) {
        const defaults: InventoryMeasureCreate = {
            canPurchase: false,
            canSell: false,
            canConsume: false,
            inventoryId: '',
            name: '',
            unitOfMeasure: '',
            value: 1,
        };

        const mergedData = mergeDeep(defaults, data);

        return InventoryMeasureCreateSchema.parse(mergedData);
    }
}

export type InventoryMeasureCreate = z.infer<typeof InventoryMeasureCreateSchema>;
