import { useEffect } from 'react';

export default function useZohoLiveChat() {
    useEffect(() => {
        const script = document.createElement('script');
        script.setAttribute('type', 'text/javascript');

        const apiKey = window.ZOHO_API_KEY;
        if (!apiKey) {
            return () => {};
        }
        const code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${apiKey}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zohopublic.com.au/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqchat'></div>";`;

        script.appendChild(document.createTextNode(code));
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);
}
