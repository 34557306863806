import Dialog from 'components/Dialogs/Dialog';
import MyButton from 'components/MyButton/MyButton';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyEditSelect from 'components/PropertyEditSelect/PropertyEditSelect';
import { useSalesItemStatusOptions } from 'features/sales/hooks/useSalesItemStatusOptions';
import { SalesItem } from 'features/sales/models/SalesItem';
import salesApi from 'features/sales/sales.api';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApiTagType } from 'services/api';
import { wait } from 'utils/helpers';
import './SalesItemBulkAssignStatusDialog.scss';

export default function SalesItemBulkAssignStatusDialog({
    items,
    close,
}: {
    items: SalesItem[];
    close?: () => void;
}) {
    const options = useSalesItemStatusOptions();
    const [selectedStatus, setSelectedStatus] = useState<string>();
    const [saveCompletedCount, setSaveCompletedCount] = useState<number>(0);
    const dialogManager = useDialogManager();

    /** percent complete of total save operation */
    const percentProgress = (saveCompletedCount * 100) / items.length;

    useEffect(() => {
        if (options?.length) {
            setSelectedStatus(options[0].value);
        }
    }, [options]);

    const [isSaving, setIsSaving] = useState(false);
    const didUserAbort = useRef(false);

    const [mutation] = salesApi.useSalesItemUpdateMutation();
    const dispatch = useDispatch();

    const applyStatus = useCallback(async () => {
        const orderLineStatusId = parseInt(selectedStatus ?? '', 10);
        if (orderLineStatusId && !Number.isNaN(orderLineStatusId)) {
            // we are going to run the save mutation for each item in the list
            // and update a progress bar as we go
            // ideally we'd batch these up into a single mutation but BE isnt done
            // and we could run multiple updates in parallel but not sure the BE can handle it
            // so...

            setSaveCompletedCount(0);
            setIsSaving(true);

            try {
                // eslint-disable-next-line no-restricted-syntax
                for (let i = 0; i < items.length; i++) {
                    const item = items[i];
                    // dont update if status is already set
                    if (item.orderLineStatusId !== orderLineStatusId) {
                        // eslint-disable-next-line no-await-in-loop
                        await mutation({
                            item: {
                                ...item,
                                orderLineStatusId,
                            },
                            invalidateTags: false,
                        }).unwrap();
                    }
                    setSaveCompletedCount(i + 1);
                    if (didUserAbort.current) {
                        break;
                    }
                    // add a short pause here to make to progress bar more dynamic
                    // eslint-disable-next-line no-await-in-loop
                    await wait(100);
                }

                // Invalidate the sales order list and detail tags
                // to trigger fetching fresh data
                dispatch(
                    salesApi.util.invalidateTags([
                        ApiTagType.SalesOrderList,
                        ApiTagType.SalesOrderDetail,
                    ]),
                );

                close?.();
            } catch (e) {
                setIsSaving(false);
                dialogManager.error(e);
            }
        }
    }, [close, dialogManager, dispatch, items, mutation, selectedStatus]);

    return (
        <Dialog
            className="SalesItemBulkAssignStatusDialog"
            allowSubtrateClose={false}
            close={close}
        >
            <PageHeader
                title="Assign status"
                subtitle="Choose a status to assign to the selected items"
            />

            {options && (
                <PropertyContainer className="SalesItemBulkAssignStatusDialog__PropertyContainer">
                    <PropertyEditSelect
                        label=""
                        options={options}
                        value={selectedStatus}
                        onChange={setSelectedStatus}
                        disabled={isSaving}
                    />
                </PropertyContainer>
            )}

            {/* Save progress panel replaces buttons when saving */}
            {isSaving ? (
                <div className="SalesItemBulkAssignStatusDialog__ProgressContainer">
                    <div>Applying status...</div>
                    <MyLinearProgress
                        variant="determinate"
                        value={percentProgress}
                        fullWidth
                        delay={0}
                    />
                    <div>
                        {saveCompletedCount} / {items.length} completed
                    </div>

                    {/* Abort button */}
                    <MyButton
                        className="SalesItemBulkAssignStatusDialog__Buttons__Cancel"
                        label="cancel"
                        buttonType="LinkButton"
                        onClick={() => {
                            didUserAbort.current = true;
                        }}
                    />
                </div>
            ) : (
                <div className="SalesItemBulkAssignStatusDialog__Buttons">
                    <MyButton
                        className="SalesItemBulkAssignStatusDialog__Buttons__Save"
                        label={`Update ${items.length} ${items.length === 1 ? 'item' : 'items'} `}
                        onClick={applyStatus}
                        isLoading={isSaving}
                    />
                    <MyButton
                        className="SalesItemBulkAssignStatusDialog__Buttons__Cancel"
                        label="Cancel"
                        buttonType="Hollow"
                        onClick={close}
                        disabled={isSaving}
                    />
                </div>
            )}
        </Dialog>
    );
}
