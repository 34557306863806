import MessagePanel from 'components/MessagePanel/MessagePanel';
import MyButton from 'components/MyButton/MyButton';
import MyMenuKebabButton from 'components/MyMenuKebabButton/MyMenuKebabButton';
import MyNumberInput from 'components/MyNumberInput/MyNumberInput';
import MyScrollableModal from 'components/MyScrollableModal/MyScrollableModal';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyEditNumber from 'components/PropertyEditNumber/PropertyEditNumber';
import InventoryReceiptStatus from 'features/inventory/enums/InventoryReceiptStatus';
import { useLocationOptions } from 'features/inventory/hooks/useLocationOptions';
import inventoryApi from 'features/inventory/inventory.api';
import {
    InventoryReceiptDetail,
    InventoryReceiptError,
    InventoryReceiptErrorDisplay,
    validateInventoryReceiptInventory,
} from 'features/inventory/models/InventoryReceiptDetail';
import { InventoryReceiptItem } from 'features/inventory/models/InventoryReceiptInventory';
import { InventoryReceiptUpdate } from 'features/inventory/models/InventoryReceiptUpdate';
import Icons from 'Icons';
import { useDialogManager } from 'providers/DialogManager';
import React, { createRef, RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import coalesceClassNames from 'utils/coalesceClassNames';
import { generateShortUuid } from 'utils/helpers';
import LocationSelectInput from '../LocationSelectInput/LocationSelectInput';
import './InventoryReceiptItemLocationModal.scss';

type InventoryReceiptItemLocationRow = {
    // ID used to track the row in the UI, as multiple rows may briefly have the same location ID.
    uuid: string;
    locationAssignment: {
        locationId: string;
        quantity: number;
    };
    animationRef: RefObject<CSSTransition<HTMLElement>>;
    inputRef: RefObject<HTMLInputElement>;
};

export function InventoryReceiptItemLocationModal({
    model,
    item,
    parentLocationId,
    onClose,
}: {
    model: InventoryReceiptDetail;
    item: InventoryReceiptItem;
    parentLocationId: string;
    onClose: () => void;
}) {
    const [flashRowId, setFlashRowId] = useState('');

    const [locationRows, setLocationRows] = useState<Array<InventoryReceiptItemLocationRow>>([]);
    const [total, setTotal] = useState<number>(0);

    const [saveMutation, saveMutationState] = inventoryApi.useInventoryReceiptUpdateMutation();
    const handleSave = useCallback(async () => {
        if (model.status !== InventoryReceiptStatus.Draft) {
            return;
        }
        const itemIndex = model.inventory.findIndex(i => i.id === item.id);
        if (itemIndex >= 0) {
            const updatedModel: InventoryReceiptUpdate = { ...model };
            const updatedInventory = [...updatedModel.inventory];

            const locationAssignments = locationRows.map(l => l.locationAssignment);

            updatedInventory.splice(itemIndex, 1, {
                ...item,
                totalQuantity: total,
                locationAssignments,
            });

            await saveMutation({
                ...model,
                inventory: updatedInventory,
            });
            onClose();
        }
    }, [item, locationRows, model, onClose, saveMutation, total]);

    // Take a copy of the locations and total count to modify in the local form
    useEffect(() => {
        setLocationRows(
            item.locationAssignments.map(l => ({
                locationAssignment: { ...l },
                uuid: generateShortUuid(),
                animationRef: createRef(),
                inputRef: createRef(),
            })),
        );
        setTotal(item.totalQuantity);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setLocationId = useCallback(
        (val, rowIndex) => {
            // Check if there are any other rows with the same location ID
            const updatedRows = [...locationRows];
            const existingRow = locationRows.find(
                (l, i) => l.locationAssignment.locationId === val && i !== rowIndex,
            );
            if (existingRow) {
                // Merge this row's count into the existing row, and then remove the duplicate
                existingRow.locationAssignment.quantity +=
                    locationRows[rowIndex].locationAssignment.quantity;
                updatedRows.splice(rowIndex, 1);
                // Put focus into other row
                existingRow.inputRef.current?.scrollIntoView();
                existingRow.inputRef.current?.focus();
                setFlashRowId(val);
            } else {
                // Update this row
                updatedRows[rowIndex].locationAssignment.locationId = val;
            }
            setLocationRows(updatedRows);
        },
        [locationRows],
    );

    const setQuantity = useCallback(
        (val, rowIndex) => {
            if (val > 0) {
                const updatedRows = [...locationRows];
                updatedRows[rowIndex].locationAssignment.quantity = val;
                setLocationRows(updatedRows);
            }
        },
        [locationRows],
    );

    const removeRow = useCallback(
        rowIndex => {
            const updatedRows = [...locationRows];
            updatedRows.splice(rowIndex, 1);
            setLocationRows(updatedRows);
        },
        [locationRows],
    );

    const [newRowId, setNewRowId] = useState(generateShortUuid());
    const addRow = useCallback(
        (locationId: string, quantity: number) => {
            // Check if a row for this location already exists
            const existingRow = locationRows.find(
                l => l.locationAssignment.locationId === locationId,
            );
            if (existingRow) {
                // Merge this row's count into the existing row
                existingRow.locationAssignment.quantity += quantity;
                // Put focus into other row
                existingRow.inputRef.current?.scrollIntoView();
                existingRow.inputRef.current?.focus();
                setFlashRowId(locationId);
                setLocationRows([...locationRows]);
                return;
            }

            // If there isn't already a row, make a new one
            const updatedRows = [...locationRows];
            const inputRef = createRef<HTMLInputElement>();
            updatedRows.push({
                locationAssignment: {
                    locationId,
                    quantity,
                },
                uuid: newRowId,
                inputRef,
                animationRef: createRef(),
            });
            updatedRows[updatedRows.length - 1].inputRef.current?.focus();
            setLocationRows(updatedRows);
            setNewRowId(generateShortUuid());
        },
        [locationRows, newRowId],
    );

    // The unassigned value is computed based on the total number and the values in each of the rows
    const unassignedQuantity = useMemo(() => {
        const totalAssigned = locationRows.reduce(
            (acc, l) => acc + l.locationAssignment.quantity,
            0,
        );
        return Math.max(total - totalAssigned, 0);
    }, [locationRows, total]);

    const assignmentLocations = useLocationOptions({ parentLocationId });

    const dialogManager = useDialogManager();
    const handleRemoveItem = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            title: 'Remove inventory',
            message: `Are you sure you want to remove this item? This cannot be undone, and locations will need to be reassigned.`,
            acceptLabel: 'Yes, remove',
            acceptButtonType: 'Danger',
        });

        if (confirm && model) {
            const updatedInventory = model.inventory.filter(
                // Keep all items except for the one that matches the InventoryReceiptItemLocationModal item ID.
                i => item.id !== i.id,
            );
            await saveMutation({ ...model, inventory: updatedInventory }).unwrap();
            onClose();
        }
    }, [dialogManager, item.id, model, onClose, saveMutation]);

    const error = validateInventoryReceiptInventory(item);

    return (
        <MyScrollableModal
            close={onClose}
            className="InventoryReceiptItemLocationModal"
            fullHeight={true}
            mobileTitle="Inventory Receipt"
            header={
                <PageHeader
                    title="Destinations"
                    titleContext={item.context.inventory.partNumber}
                    subtitle={item.context.inventory.description}
                >
                    <MyMenuKebabButton
                        menuItems={[
                            model?.status === InventoryReceiptStatus.Draft &&
                                !model.isArchived && {
                                    label: 'Remove Item',
                                    IconLeft: Icons.Archive,
                                    onClick: handleRemoveItem,
                                },
                        ]}
                    />
                </PageHeader>
            }
            footer={
                <div className="InventoryReceiptItemLocationModal__Footer">
                    <MyButton
                        label="Cancel"
                        buttonType="Hollow"
                        onClick={onClose}
                    />
                    <MyButton
                        label="Save"
                        buttonType="Primary"
                        isLoading={saveMutationState.isLoading}
                        onClick={handleSave}
                    />
                </div>
            }
        >
            <div>
                {error && (
                    <MessagePanel
                        messageType="warning"
                        className="InventoryReceiptItemLocationModal__MessagePanel"
                    >
                        There is an error with this item:{' '}
                        {InventoryReceiptErrorDisplay.display(error)}
                    </MessagePanel>
                )}
                <TransitionGroup component={null}>
                    {locationRows.map((l, rowIndex) => (
                        <CSSTransition
                            key={l.uuid}
                            timeout={200}
                            classNames=""
                            ref={l.animationRef}
                        >
                            <div
                                key={l.locationAssignment.locationId}
                                className={coalesceClassNames(
                                    'InventoryReceiptItemLocationModal__Location',
                                    flashRowId === l.locationAssignment.locationId && 'flash',
                                )}
                            >
                                <LocationSelectInput
                                    value={l.locationAssignment.locationId}
                                    onChange={val => setLocationId(val, rowIndex)}
                                    locations={assignmentLocations}
                                    disabled={error === InventoryReceiptError.InventoryArchived}
                                />
                                <MyNumberInput
                                    className="InventoryReceiptItemLocationModal__Location__Quantity"
                                    withButtons={true}
                                    value={l.locationAssignment.quantity}
                                    onChange={v => setQuantity(v, rowIndex)}
                                    min={1}
                                    max={total}
                                    allowBlank={false}
                                    inputRef={l.inputRef}
                                    disabled={error === InventoryReceiptError.InventoryArchived}
                                />
                                <MyButton
                                    className="InventoryReceiptItemLocationModal__Location__Remove"
                                    IconLeft={Icons.Close}
                                    buttonType="Nude"
                                    onClick={() => removeRow(rowIndex)}
                                />
                            </div>
                        </CSSTransition>
                    ))}
                    {unassignedQuantity > 0 && (
                        <CSSTransition
                            key={newRowId}
                            timeout={{ enter: 200, exit: 0 }}
                            classNames=""
                        >
                            <div className="InventoryReceiptItemLocationModal__Location unassigned">
                                <LocationSelectInput
                                    value={''}
                                    onChange={val => addRow(val, unassignedQuantity)}
                                    locations={assignmentLocations}
                                    disabled={error === InventoryReceiptError.InventoryArchived}
                                />
                                <PropertyEditNumber
                                    className="InventoryReceiptItemLocationModal__Location__Quantity"
                                    label=""
                                    withButtons={true}
                                    value={unassignedQuantity}
                                    min={unassignedQuantity}
                                    max={unassignedQuantity}
                                    allowBlank={false}
                                    disabled={true}
                                />
                            </div>
                        </CSSTransition>
                    )}
                </TransitionGroup>
                <div className="InventoryReceiptItemLocationModal__Total_Row">
                    <span>Total</span>
                    <PropertyEditNumber
                        className="InventoryReceiptItemLocationModal__Location__Quantity"
                        label=""
                        withButtons={true}
                        value={total}
                        onChange={val => val !== undefined && setTotal(val)}
                        min={1}
                        allowBlank={false}
                        disabled={error === InventoryReceiptError.InventoryArchived}
                    />
                </div>
            </div>
        </MyScrollableModal>
    );
}
