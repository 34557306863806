import { faker } from '@faker-js/faker';
import { fakeSalesOrders, getFakeSalesItemOptions } from 'features/sales/faker/SalesFaker';
import { generateFakeSchedule } from 'features/schedule/faker/ScheduleFaker';
import { DateTime } from 'luxon';
import StepCanTransition from '../enums/StepCanTransition';
import WorkflowStepType from '../enums/WorkflowStepType';
import WorkItemStatus from '../enums/WorkItemStatus';
import WorkItemStepStatus from '../enums/WorkItemStepStatus';
import { WorkOrderDetail } from '../models/WorkOrderDetail';
import { WorkOrderItemDetail } from '../models/WorkOrderItemDetail';
import { WorkOrderItemStepSummary } from '../models/WorkOrderItemStepSummary';
import { WorkOrderItemWithOptionsSchema } from '../models/WorkOrderItemWithOptions';
import { WorkOrderItemWithStateSchema } from '../models/WorkOrderItemWithState';

faker.seed(42);

export function generateFakeWorkOrderDetail(): WorkOrderDetail {
    return {
        id: faker.string.uuid(),
        tuid: `WRK${faker.number.int({ min: 1, max: 99999 }).toString().padStart(5, '0')}`,
        notes: '',

        context: {
            workOrderItems: [],
            schedule: {
                id: 'dc0d21e2-00a6-4373-a3aa-809e6cc3d4a6',
                date: faker.date.soon().toDateString().substring(0, 10),
            },
            scheduleMeta: {
                sortOrder: 5,
            },
            workstationGroupAssignment: undefined,
        },
    };
}

const fakeStepNames = ['Do a little dance', 'Make a little love', 'Get down tonight'];
function generateFakeWorkItemStep(workOrderItem: WorkOrderItemDetail): WorkOrderItemStepSummary {
    const workflowStepId = faker.string.uuid();
    return {
        id: faker.string.uuid(),
        workflowStepId,
        workOrderItemId: workOrderItem.id,
        status: faker.helpers.enumValue(WorkItemStepStatus),
        lastTransitionTimestamp: undefined,
        lastTransitionWorkstationId: undefined,
        lastTransitionPrincipalId: undefined,
        lastTransitionReason: undefined,
        sortOrder: faker.number.int(),
        context: {
            workOrderItem: WorkOrderItemWithOptionsSchema.parse(workOrderItem),
            workflowStep: {
                id: workflowStepId,
                name: faker.helpers.arrayElement(fakeStepNames),
                stepType: WorkflowStepType.Default,
            },
            workstationLatest: undefined,
            workOrderItemStepsBlocking: [],
            canTransition: faker.helpers.enumValue(StepCanTransition),
        },
    };
}

/** Map an array of options/syth options or labels to a hash object */
function mapOptionArray(arr?: { name: any; value: any }[] | null): Record<string, any> {
    const map = new Map(arr?.map(obj => [obj.name, obj.value]));
    return Object.fromEntries(map);
}

function getItemStateFromSteps(steps: WorkOrderItemStepSummary[]): WorkItemStatus {
    if (steps.some(s => s.status === WorkItemStepStatus.OnHold)) {
        return WorkItemStatus.OnHold;
    }
    if (
        steps.every(s =>
            [
                WorkItemStepStatus.Completed,
                WorkItemStepStatus.CompletedLocked,
                WorkItemStepStatus.Skipped,
                WorkItemStepStatus.SkippedLocked,
            ].includes(s.status),
        )
    ) {
        return WorkItemStatus.Completed;
    }

    if (steps.every(s => s.status === WorkItemStepStatus.Unstarted)) {
        return WorkItemStatus.Unstarted;
    }

    return WorkItemStatus.InProgress;
}

export function generateFakeWorkItem(workOrder: WorkOrderDetail): WorkOrderItemDetail {
    const salesOrder = fakeSalesOrders.find(
        s => `${s.id}` === workOrder.context.workOrderItems[0]?.salesOrderId,
    );
    const salesItem =
        (salesOrder?.context.orderLines.length ?? 0) > 0
            ? faker.helpers.arrayElement(salesOrder?.context.orderLines ?? [])
            : undefined;

    const fakeOptions = getFakeSalesItemOptions();
    const options = mapOptionArray(fakeOptions.options);
    const sythOptions = mapOptionArray(fakeOptions.synthesized_options);
    const labels = mapOptionArray(fakeOptions.labels);

    const schedule = generateFakeSchedule(
        DateTime.fromISO(faker.date.soon({ days: 10 }).toISOString()).startOf('day'),
    );

    const workItem: WorkOrderItemDetail = {
        id: faker.string.uuid(),
        tuid: `WI${faker.number.int({ min: 1, max: 99999 }).toString().padStart(5, '0')}`,
        description: faker.commerce.productName(),
        customerOrderReference: '',
        customerOrderSidemark: '',
        salesOrderId: `${salesOrder?.id ?? ''}`,
        salesOrderLineId: `${salesItem?.id ?? ''}`,
        salesOrderReference: salesOrder?.manufacturerReference ?? '',
        isCancelled: false,
        workflowId: faker.string.uuid(),
        workOrderId: workOrder.id,
        labels: { ...labels },
        options: {
            ...options,
            ...sythOptions,
        },
        context: {
            workOrder: { ...workOrder },
            workOrderItemState: faker.helpers.enumValue(WorkItemStatus), // this is modified below
            schedule,
            scheduleMeta: {
                sortOrder: 0,
            },
            workOrderItemSteps: [],
        },
    };

    // generate steps
    workItem.context.workOrderItemSteps = faker.helpers.multiple(
        () => generateFakeWorkItemStep(workItem),
        {
            count: faker.number.int({ min: 0, max: 10 }),
        },
    );

    // fix the item status to match the steps
    workItem.context.workOrderItemState = getItemStateFromSteps(
        workItem.context.workOrderItemSteps,
    );

    return workItem;
}

export const fakeWorkOrders = faker.helpers.multiple(generateFakeWorkOrderDetail, { count: 20 });

// populate work items
export const fakeWorkItemDetails: WorkOrderItemDetail[] = [];
fakeWorkOrders.forEach(wo => {
    faker.helpers.multiple(
        () => {
            const detail = generateFakeWorkItem(wo);
            fakeWorkItemDetails.push(detail);
            const itemSummary = WorkOrderItemWithStateSchema.parse(detail);
            wo.context.workOrderItems.push(itemSummary);
        },
        { count: faker.number.int({ min: 1, max: 10 }) },
    );
});
