import z from 'zod';
import CustomerAccessType from '../enums/CustomerAccessType';

export const CompanySchema = z.object({
    id: z.number(),
    name: z.string(),
    email: z.string(),
    phone: z.string().nullable(),
    addresses: z.array(
        z.object({
            id: z.number(),
            full_address: z.string(),
        }),
    ),
});
export type Company = z.infer<typeof CompanySchema>;

/* Data coming back from the old api /manufacturer-customers endpoint
 Which is translated into our new models when loaded
TODO reimplement the api so we dont have to do this */
export const CustomerListOldDataSchema = z.object({
    total: z.number(),
    // array of sales order groups
    data: z.array(
        z.object({
            id: z.number(),
            email: z.string(),
            company_name: z.string(),
            company_phone: z.string().nullable(),
            tax_applicable: z.number().nullable(), // 0 or 1
            existing_customer: z.number(), // 0 or 1
            manufacturer_status: z.string().nullable(),
            is_internal: z.number().nullable(), // 0 or 1
            manufacturer_orders_count: z.number(),
            payment_method: z.string().nullable(),
            credit_limit: z.number().nullable(),
            credit_terms: z.string().nullable(),
            shipping_details: z.string().nullable(),
            manufacturer_notes: z.string().nullable(),
            access_type: z.nativeEnum(CustomerAccessType),
            main_company: CompanySchema,
            brands: z.array(
                z.object({
                    id: z.number(),
                    name: z.string(),
                    is_active: z.number(),
                }),
            ),
            tax_exemptions: z
                .array(
                    z.object({
                        state: z.string(),
                        reason: z.string(),
                    }),
                )
                .nullable(),
        }),
    ),
});

export type CustomerListOldData = z.infer<typeof CustomerListOldDataSchema>;
