import { Tooltip } from '@mui/material';
import React from 'react';

import WorkItemStatusBadge from 'features/workOrders/components/WorkItemStatusBadge/WorkItemStatusBadge';
import WorkItemStatus from 'features/workOrders/enums/WorkItemStatus';
import './WorkItemsProgress.scss';

type BasicWorkItem = {
    context: {
        workOrderItemState: WorkItemStatus;
    };
};

// Progress bar showing a summary of manufacturer orders and their work Item statuses
export default function WorkItemsProgress({
    workItems,
    showPercentage,
}: {
    workItems: BasicWorkItem[];
    showPercentage?: boolean;
}) {
    const totalCount = workItems.length;
    const countCompleted = workItems.filter(
        item => item.context.workOrderItemState === WorkItemStatus.Completed,
    ).length;
    const countInProgress = workItems.filter(
        item => item.context.workOrderItemState === WorkItemStatus.InProgress,
    ).length;
    const countOnHold = workItems.filter(
        item => item.context.workOrderItemState === WorkItemStatus.OnHold,
    ).length;
    const countUnstarted = workItems.filter(
        item => item.context.workOrderItemState === WorkItemStatus.Unstarted,
    ).length;

    const percentCompleted = (countCompleted / totalCount) * 100;
    const percentInProgress = (countInProgress / totalCount) * 100;
    const percentOnHold = (countOnHold / totalCount) * 100;
    const percentUnstarted = (countUnstarted / totalCount) * 100;

    return (
        <Tooltip
            title={
                <div className="WorkItemsProgress__Tooltip">
                    {showPercentage && (
                        <div className="WorkItemsProgress__Tooltip__Header">
                            {totalCount} {totalCount === 1 ? 'item' : 'items'}, {countCompleted}{' '}
                            complete ({percentCompleted.toFixed(0)}%)
                        </div>
                    )}
                    <div className="WorkItemsProgress__Tooltip__Statuses">
                        <TooltipWorkItemStatus
                            status={WorkItemStatus.Completed}
                            count={countCompleted}
                        />
                        <TooltipWorkItemStatus
                            status={WorkItemStatus.InProgress}
                            count={countInProgress}
                        />
                        <TooltipWorkItemStatus
                            status={WorkItemStatus.OnHold}
                            count={countOnHold}
                        />
                        <TooltipWorkItemStatus
                            status={WorkItemStatus.Unstarted}
                            count={countUnstarted}
                        />
                    </div>
                </div>
            }
            placement="top"
            disableInteractive={true}
            arrow
        >
            <div className="WorkItemsProgress">
                <div className="WorkItemsProgress__Bar">
                    <div
                        className={`WorkItemsProgress__Bar__Progress ${WorkItemStatus.Completed}`}
                        style={{ width: `${percentCompleted}%` }}
                    />
                    <div
                        className={`WorkItemsProgress__Bar__Progress ${WorkItemStatus.InProgress}`}
                        style={{ width: `${percentInProgress}%` }}
                    />
                    <div
                        className={`WorkItemsProgress__Bar__Progress ${WorkItemStatus.OnHold}`}
                        style={{ width: `${percentOnHold}%` }}
                    />
                    <div
                        className={`WorkItemsProgress__Bar__Progress ${WorkItemStatus.Unstarted}`}
                        style={{ width: `${percentUnstarted}%` }}
                    />
                </div>
                {showPercentage && (
                    <div className="WorkItemsProgress__Percent">{percentCompleted.toFixed(0)}%</div>
                )}
            </div>
        </Tooltip>
    );
}

function TooltipWorkItemStatus({ status, count }: { status: WorkItemStatus; count: number }) {
    if (count === 0) {
        return null;
    }

    return (
        <div className="WorkItemsProgress__TooltipWorkItemStatus">
            <WorkItemStatusBadge
                className="WorkItemsProgress__TooltipWorkItemStatus__Badge"
                status={status}
                badgeType="text"
            />
            <div className="WorkItemsProgress__TooltipWorkItemStatus__Value">{count}</div>
        </div>
    );
}
