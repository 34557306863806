import MyEditModal from 'components/MyEditModal/MyEditModal';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import dispatchApi from 'features/dispatch/dispatch.api';
import { PackageEntity } from 'features/dispatch/models/PackageEntity';
import LocationSelectInput from 'features/inventory/components/LocationSelectInput/LocationSelectInput';
import React from 'react';
import './PackageEditModal.scss';

export default function PackageEditModal({
    model,
    close,
}: {
    model?: PackageEntity;
    close?: () => void;
}) {
    const [saveMutation, saveMutationState] = dispatchApi.usePackageUpdateMutation();

    const save = async (editModel: PackageEntity) => {
        await saveMutation(editModel).unwrap();
        close?.();
    };

    return (
        <MyEditModal
            className="PackageEditModal"
            model={model}
            editImmediately={true}
            fullHeight={false}
            close={close}
            onSave={save}
            isSaving={saveMutationState.isLoading}
            title="Edit package"
            titleContext={model?.tuid}
            mobileTitle="Package"
        >
            {({ editModel, updateField, isSaving }) => (
                <PropertyContainer>
                    <PropertyEditText
                        label="Description"
                        value={editModel.description}
                        onChange={description => updateField({ description })}
                        disabled={isSaving}
                        validationRequired={true}
                    />
                    <PropertyDisplay
                        label="Location"
                        value={
                            <LocationSelectInput
                                value={editModel.locationId}
                                // locations={locationsWithCanStore}
                                onChange={locationId => updateField({ locationId })}
                                disabled={isSaving}
                                validationRequired="Please select a location"
                            />
                        }
                    />
                    <PropertyEditText
                        label="Dimensions"
                        value={editModel.dimensions}
                        onChange={dimensions => updateField({ dimensions })}
                        disabled={isSaving}
                    />
                    <PropertyEditText
                        label="Weight"
                        value={editModel.weight}
                        onChange={weight => updateField({ weight })}
                        disabled={isSaving}
                    />
                    <PropertyEditText
                        label="Notes"
                        value={editModel.notes}
                        onChange={notes => updateField({ notes })}
                        multiline
                        disabled={isSaving}
                    />
                </PropertyContainer>
            )}
        </MyEditModal>
    );
}
