import MyButton from 'components/MyButton/MyButton';
import MyScrollableModal from 'components/MyScrollableModal/MyScrollableModal';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyEditNumber from 'components/PropertyEditNumber/PropertyEditNumber';
import { useLocationOptions } from 'features/inventory/hooks/useLocationOptions';
import inventoryApi from 'features/inventory/inventory.api';
import { Inventory } from 'features/inventory/models/Inventory';
import { InventoryReceiptDetail } from 'features/inventory/models/InventoryReceiptDetail';
import { InventoryReceiptItem } from 'features/inventory/models/InventoryReceiptInventory';
import { InventoryReceiptUpdate } from 'features/inventory/models/InventoryReceiptUpdate';
import Icons from 'Icons';
import React, { createRef, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import coalesceClassNames from 'utils/coalesceClassNames';
import LocationSelectInput from '../LocationSelectInput/LocationSelectInput';
import './InventoryReceiptAddItemModal.scss';
import InventoryReceiptInventoryInput from './InventoryReceiptInventoryInput';

interface InventoryReceiptViewItem {
    inventory: Inventory;
    quantity: number;
    scrollRef: RefObject<HTMLDivElement>;
    animationRef: RefObject<CSSTransition<HTMLElement>>;
}

export function InventoryReceiptAddItemModal({
    model,
    parentLocationId,
    onClose,
}: {
    model: InventoryReceiptDetail;
    parentLocationId: string;
    onClose: () => void;
}) {
    const [items, setItems] = useState<InventoryReceiptViewItem[]>([]);
    const [saveMutation, saveMutationState] = inventoryApi.useInventoryReceiptUpdateMutation();

    const [locationId, setLocationId] = useState('');
    const locations = useLocationOptions({ parentLocationId });

    const wrapperRef = useRef<HTMLDivElement>(null);

    const save = async () => {
        const inventory = makeInventory(items, model.inventory, locationId);
        const updatedModel: InventoryReceiptUpdate = { ...model, inventory };
        await saveMutation(updatedModel).unwrap();
        onClose?.();
    };

    const [flashRowId, setFlashRowId] = useState('');

    useEffect(() => {
        if (flashRowId) {
            const timeoutId = setTimeout(() => setFlashRowId(''), 400);
            return () => clearTimeout(timeoutId);
        }
        return undefined;
    }, [flashRowId]);

    const setItemQuantity = useCallback(
        (inventory: Inventory, quantity: number | undefined) => {
            if (inventory && quantity !== undefined && quantity > 0) {
                // Once the update occurs, this item will flash and be scrolled into view
                setFlashRowId(inventory.id);
                let scrollRef: RefObject<HTMLDivElement>;
                // Get the existing item
                const itemIndex = items.findIndex(i => i.inventory.id === inventory.id);
                if (itemIndex >= 0) {
                    // If the item was found, update its quantity and push the update back to the items array
                    const updatedItem = { ...items[itemIndex], inventory, quantity };
                    const updatedItems = [...items];
                    updatedItems.splice(itemIndex, 1, updatedItem);
                    setItems(updatedItems);
                    // The item was found, so scroll to it once the updates were made.
                    scrollRef = updatedItem.scrollRef;
                } else {
                    // No item was found, so make a new entry and set the scroll reference
                    scrollRef = createRef();
                    const newItem: InventoryReceiptViewItem = {
                        inventory,
                        quantity,
                        scrollRef,
                        animationRef: createRef(),
                    };
                    setItems([newItem, ...items]);
                }
                requestAnimationFrame(() => {
                    // Once the updates are made, trigger animations
                    // The flashing of the row will be triggered by the item rerenders, but the scrolling is manually requested.
                    if (scrollRef.current) {
                        scrollRef.current.scrollIntoView();
                    }
                });
            }
        },
        [items],
    );

    const removeItem = useCallback(
        (inventoryId: string) => {
            setItems(items.filter(i => i.inventory.id !== inventoryId));
        },
        [items],
    );

    const handleSelectInventory = useCallback(
        (inventory: Inventory) => {
            if (inventory) {
                const item = items.find(i => i.inventory.id === inventory.id);
                setItemQuantity(inventory, (item?.quantity ?? 0) + 1);
            }
        },
        [items, setItemQuantity],
    );

    return (
        <MyScrollableModal
            close={onClose}
            className="InventoryReceiptAddItemModal"
            fullHeight={true}
            disableBackgroundClose={true}
            mobileTitle="Inventory Receipt"
            header={
                <>
                    <PageHeader
                        className="MyEditModal__Header__PageHeader"
                        title="Add Inventory Items"
                        subtitle="Add items to this receipt by searching below"
                    />
                    <div className="InventoryReceiptAddItemModal__InventoryReceiptInventoryInputWrapper">
                        <InventoryReceiptInventoryInput
                            className="InventoryReceiptAddItemModal__InventoryReceiptInventoryInput"
                            onChange={inventory => handleSelectInventory(inventory)}
                        />
                    </div>
                </>
            }
            footer={
                <div className="InventoryReceiptAddItemModal__Footer">
                    <LocationSelectInput
                        value={locationId}
                        onChange={setLocationId}
                        locations={locations}
                        label="Assign to location"
                        disabled={saveMutationState.isLoading}
                    />
                    <MyButton
                        className="InventoryReceiptAddItemModal__Footer__Cancel"
                        label="Cancel"
                        buttonType="Hollow"
                        onClick={onClose}
                        disabled={saveMutationState.isLoading}
                    />
                    <MyButton
                        className="InventoryReceiptAddItemModal__Footer__AddItems"
                        label="Add Items"
                        buttonType="Primary"
                        onClick={save}
                        isLoading={saveMutationState.isLoading}
                        disabled={items.length === 0}
                        fullWidth
                    />
                </div>
            }
        >
            <div
                className="InventoryReceiptAddItemModal__ItemTable"
                ref={wrapperRef}
            >
                <TransitionGroup component={null}>
                    {items.length === 0 && (
                        <CSSTransition
                            key="EMPTY_LIST"
                            timeout={{ enter: 400, exit: 0 }}
                            classNames=""
                        >
                            <div className="InventoryReceiptAddItemModal__ItemTable__EmptyRow">
                                Use the inventory search field to add items
                            </div>
                        </CSSTransition>
                    )}
                    {items.map(item => (
                        <CSSTransition
                            key={item.inventory.id}
                            timeout={200}
                            classNames=""
                            ref={item.animationRef}
                        >
                            <div
                                className={coalesceClassNames(
                                    'InventoryReceiptAddItemModal__ItemRow',
                                    flashRowId === item.inventory.id && 'flash',
                                )}
                                ref={item.scrollRef}
                            >
                                <div className="InventoryReceiptAddItemModal__InventoryLabel">
                                    {item.inventory.description}
                                </div>

                                <PropertyEditNumber
                                    className="InventoryReceiptAddItemModal__PropertyEdit__Quantity"
                                    label="Quantity"
                                    withButtons={true}
                                    value={item.quantity}
                                    onChange={v => setItemQuantity(item.inventory, v)}
                                    min={1}
                                    allowBlank={false}
                                />
                                <MyButton
                                    className="InventoryReceiptAddItemModal__PropertyEdit__Remove"
                                    IconLeft={Icons.Close}
                                    buttonType="Nude"
                                    onClick={() => removeItem(item.inventory.id)}
                                />
                            </div>
                        </CSSTransition>
                    ))}
                </TransitionGroup>
            </div>
        </MyScrollableModal>
    );
}

function makeInventory(
    items: InventoryReceiptViewItem[],
    modelInventory: InventoryReceiptItem[],
    locationId?: string,
): InventoryReceiptUpdate['inventory'] {
    // Take a deep copy of the inventory that can be modified with updated quantities and locations
    const updatedInventory: InventoryReceiptUpdate['inventory'] = JSON.parse(
        JSON.stringify(modelInventory),
    );

    items.forEach(item => {
        const inventoryIndex = updatedInventory.findIndex(i => i.id === item.inventory.id);

        if (inventoryIndex >= 0) {
            // If the inventory to be added already exists, then we need to update the existing inventory record.
            const updatedItem = updatedInventory[inventoryIndex];
            updatedItem.totalQuantity += item.quantity;

            if (locationId) {
                const locationIndex = updatedItem.locationAssignments.findIndex(
                    l => l.locationId === locationId,
                );
                if (locationIndex >= 0) {
                    // If the location already has this inventory assigned to it, update the quantity
                    updatedItem.locationAssignments[locationIndex].quantity += item.quantity;
                } else {
                    // Otherwise create a new location record to assign the quantity to
                    updatedItem.locationAssignments.push({
                        locationId,
                        quantity: item.quantity,
                    });
                }
            }
        } else {
            // Create a new inventory record
            const locationAssignments = locationId ? [{ locationId, quantity: item.quantity }] : [];
            updatedInventory.push({
                id: item.inventory.id,
                totalQuantity: item.quantity,
                locationAssignments,
            });
        }
    });

    return updatedInventory;
}
