import MyModal from 'components/MyModal/MyModal';
import inventoryApi from 'features/inventory/inventory.api';
import { Inventory } from 'features/inventory/models/Inventory';
import { InventoryLocationJoinDetail } from 'features/inventory/models/InventoryLocationJoinDetail';
import Icons from 'Icons';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import InventoryLocationDetailsMovements from '../InventoryLocationDetailsMovements/InventoryLocationDetailsMovements';
import './InventoryLocationJoinDetailModal.scss';

export default function InventoryLocationJoinDetailModal({
    inventory,
    locationId,
    isLoading,
    isError,
    close,
}: {
    inventory?: Inventory;
    locationId: string;
    isLoading: boolean;
    isError: boolean;
    close?: () => void;
}) {
    const query = inventoryApi.useLocationInventoryJoinDetailQuery(
        {
            inventoryId: inventory?.id ?? '',
            locationId,
        },
        {
            skip: !inventory,
        },
    );

    const path = useLocation();

    return (
        <MyModal
            mobileTitle={
                path.pathname.startsWith('/inventory')
                    ? 'Inventory'
                    : path.pathname.startsWith('/location')
                    ? 'Location'
                    : 'Back'
            }
            className="InventoryLocationJoinDetailModal"
            close={close}
            isLoading={isLoading || query.isLoading}
            isError={isError || query.isError}
            header={
                <>
                    {inventory && query.data && (
                        <ModalHeader
                            inventory={inventory}
                            inventoryLocationJoin={query.data}
                        />
                    )}
                </>
            }
        >
            {inventory && (
                <InventoryLocationDetailsMovements
                    inventoryId={inventory?.id}
                    locationId={locationId}
                />
            )}
        </MyModal>
    );
}

function ModalHeader({
    inventory,
    inventoryLocationJoin,
}: {
    inventory: Inventory;
    inventoryLocationJoin: InventoryLocationJoinDetail;
}) {
    return (
        <>
            <div>
                <div className="InventoryLocationJoinDetailModal__LocationPaths">
                    {inventoryLocationJoin.context.locationPath?.map((p, i) => (
                        <div key={p.id}>
                            {i === (inventoryLocationJoin.context.locationPath ?? []).length - 1 ? (
                                <Link
                                    to={`/locations/${p.id}`}
                                    className="InventoryLocationJoinDetailModal__EntityLink"
                                >
                                    {p.name}{' '}
                                    <Icons.ExternalLink className="InventoryLocationJoinDetailModal__Icon" />
                                </Link>
                            ) : (
                                <span className="InventoryLocationJoinDetailModal__ParentLocation">
                                    {p.name}
                                    <Icons.ChevronRight className="InventoryLocationJoinDetailModal__Icon" />
                                </span>
                            )}
                        </div>
                    ))}
                </div>
                <div className="InventoryLocationJoinDetailModal__Title">
                    <span>Location Stock</span>
                    <div className="InventoryLocationJoinDetailModal__Title__Context">
                        <span>&nbsp;| {inventory?.partNumber} </span>
                        <Link
                            to={`/inventory/${inventory?.id}`}
                            className="InventoryLocationJoinDetailModal__Title__EntityLink"
                        >
                            <Icons.ExternalLink className="InventoryLocationJoinDetailModal__Title__Icon" />
                        </Link>
                    </div>
                </div>
                <div className="InventoryLocationJoinDetailModal__Title__Description">
                    {inventory?.description}
                </div>
            </div>
            <div>
                <div className="InventoryLocationJoinDetailModal__CardWrapper">
                    <div className="InventoryLocationJoinDetailModal__Card">
                        <div className="InventoryLocationJoinDetailModal__Card__Label">On Hand</div>
                        <div className="InventoryLocationJoinDetailModal__Card__Count">
                            {inventoryLocationJoin?.quantityOnHand}
                        </div>
                    </div>
                    <div className="InventoryLocationJoinDetailModal__Card">
                        <div className="InventoryLocationJoinDetailModal__Card__Label">
                            Allocated
                        </div>
                        <div className="InventoryLocationJoinDetailModal__Card__Count">
                            {inventoryLocationJoin?.quantityAllocated}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
