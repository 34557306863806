import MySwitchInput from 'components/MySwitchInput/MySwitchInput';
import PropertyEdit from 'components/PropertyEdit/PropertyEdit';
import React, { useRef } from 'react';
import { generateShortUuid } from 'utils/helpers';

export type PropertyEditSwitchProps = {
    checked?: boolean;
    label: string;
    hint?: string;
    onChange?: (val?: boolean) => void;
    readonly?: boolean;
    disabled?: boolean;
    validationRequired?: string | boolean;
    validationCustom?: string | false;
};

export default function PropertyEditSwitch({
    checked,
    label,
    hint,
    onChange,
    readonly = false,
    disabled = false,
    validationRequired,
    validationCustom,
}: PropertyEditSwitchProps) {
    const inputId = useRef(generateShortUuid()).current;

    const validationRequiredWithLabel =
        validationRequired === true ? `${label} is required` : validationRequired;

    return (
        <PropertyEdit
            className="PropertyEditSwitch"
            label={label}
            hint={hint}
            inputId={inputId}
        >
            <MySwitchInput
                className="PropertyEditSwitch__Input"
                id={inputId}
                checked={checked}
                handleInput={onChange}
                disabled={disabled}
                readOnly={readonly}
                validationRequired={validationRequiredWithLabel}
                validationCustom={validationCustom}
            />
        </PropertyEdit>
    );
}
