import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum CustomerType {
    External = 'external',
    InternalNonTaxable = 'internalNonTaxable',
    InternalTaxable = 'internalTaxable',
}

export default CustomerType;

const labels: EnumDisplayLabels<typeof CustomerType> = {
    External: 'External',
    InternalNonTaxable: 'Internal Non taxable',
    InternalTaxable: 'Internal Taxable',
};

export const CustomerTypeDisplay = createDisplayEnum(CustomerType, labels);
