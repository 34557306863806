import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useUrlQueryState(
    key: string,
    defaultValue = '',
    options?: { clearOnUnmount?: boolean },
) {
    const navigate = useNavigate();

    const setValue = useCallback(
        (val: string, opts?: { replace?: boolean }) => {
            const searchParams = new URLSearchParams(window.location.search);

            if (!val || val === defaultValue) {
                searchParams.delete(key);
            } else {
                searchParams.set(key, val);
            }

            const url = (searchParams as any).size
                ? `${window.location.pathname}?${searchParams}`
                : window.location.pathname;

            navigate(url, opts);
        },
        [defaultValue, key, navigate],
    );

    useEffect(() => {
        return () => {
            if (options?.clearOnUnmount) {
                setValue('');
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const currentValue = new URLSearchParams(window.location.search).get(key) || defaultValue;
    return [currentValue, setValue] as const;
}
