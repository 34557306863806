import FieldValidator from 'components/FieldValidator/FieldValidator';
import MyNumberInput from 'components/MyNumberInput/MyNumberInput';
import MySelectInput from 'components/MySelectInput/MySelectInput';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEditSwitch from 'components/PropertyEditSwitch/PropertyEditSwitch';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import inventoryApi from 'features/inventory/inventory.api';
import { InventoryMeasureFamily } from 'features/inventory/models/InventorySIMeasureListResult';
import React, { useMemo } from 'react';
import './InventoryMeasureEditFields.scss';

export type InventoryMeasureEdit = {
    id?: string;
    canPurchase: boolean;
    canSell: boolean;
    canConsume: boolean;
    inventoryId: string;
    name: string;
    unitOfMeasure: string;
    value: number;
};

export function InventoryMeasureEditFields({
    model,
    inventoryMeasureFamily,
    isNew,
    isSaving,
    updateField,
}: {
    model: InventoryMeasureEdit;
    inventoryMeasureFamily: InventoryMeasureFamily;
    isNew: boolean;
    isSaving: boolean;
    updateField: (fields: Partial<InventoryMeasureEdit>) => void;
}) {
    const siMeasuresQuery = inventoryApi.useInventorySIMeasureListQuery();

    const displayUnitOptions = useMemo(
        () =>
            siMeasuresQuery.data
                ?.filter(m => m.family === inventoryMeasureFamily)
                .map(m => ({
                    label: m.description,
                    value: m.name,
                })) ?? [],
        [inventoryMeasureFamily, siMeasuresQuery.data],
    );

    return (
        <PropertyContainer layout="table">
            <PropertyEditText
                label="Name"
                value={model.name}
                onChange={name => updateField({ name })}
                multiline={true}
                disabled={isSaving}
                validationRequired={true}
            />
            <PropertyDisplay
                className="InventoryMeasureEditFields__Value"
                label="Value"
                value={
                    <FieldValidator
                        value={model.value}
                        validationCustom={
                            model.value <= 0
                                ? 'Value must be greater than zero'
                                : !model.unitOfMeasure
                                ? 'Unit is required'
                                : ''
                        }
                    >
                        {({ revealError, error }) => (
                            <PropertyContainer
                                className="InventoryMeasureEditFields__Value"
                                layout="row"
                            >
                                <MyNumberInput
                                    value={model.value}
                                    onChange={val =>
                                        updateField({
                                            value: val,
                                        })
                                    }
                                    error={!!error}
                                    disabled={!isNew || isSaving}
                                    min={0}
                                    onBlur={revealError}
                                />
                                <MySelectInput
                                    value={model.unitOfMeasure}
                                    options={displayUnitOptions}
                                    handleInput={unitOfMeasure => updateField({ unitOfMeasure })}
                                    allowBlank={false}
                                    disabled={!isNew}
                                    onBlur={revealError}
                                />
                            </PropertyContainer>
                        )}
                    </FieldValidator>
                }
            />
            <PropertyEditSwitch
                label="Purchase"
                checked={model.canPurchase}
                onChange={canPurchase => updateField({ canPurchase })}
                disabled={isSaving}
            />
            <PropertyEditSwitch
                label="Sell"
                checked={model.canSell}
                onChange={canSell => updateField({ canSell })}
                disabled={isSaving}
            />
            <PropertyEditSwitch
                label="Consume"
                checked={model.canConsume}
                onChange={canConsume => updateField({ canConsume })}
                disabled={isSaving}
            />
        </PropertyContainer>
    );
}
