import MyEditModal from 'components/MyEditModal/MyEditModal';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyEditSelect from 'components/PropertyEditSelect/PropertyEditSelect';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import customersApi from 'features/customers/customers.api';
import { Customer, CustomerTaxExemption } from 'features/customers/models/Customer';
import { USStateDisplay } from 'features/customers/models/TaxExemptions';
import React from 'react';
import './CustomerTaxExemptionEditModal.scss';

export default function CustomerTaxExemptionEditModal({
    taxExemption,
    customer,
    close,
}: {
    taxExemption: CustomerTaxExemption;
    customer: Customer;
    close?: () => void;
}) {
    const [saveMutation, saveMutationState] = customersApi.useCustomerUpdateMutation();

    const isNew = !customer.taxExemptions?.includes(taxExemption);

    const save = async (editModel: CustomerTaxExemption) => {
        // find the index of tax exemptions in the customer model and replace it with the new one
        // or append it to the end of the list
        const taxExemptions = [...(customer.taxExemptions ?? [])];
        if (isNew) {
            taxExemptions.push(editModel);
        } else {
            // editing an existing tax exemption
            // remove it and replace with a new one
            const index = taxExemptions.findIndex(t => t === taxExemption);
            taxExemptions.splice(index, isNew ? 0 : 1, editModel);
        }

        const customerCopy = { ...customer, taxExemptions };
        await saveMutation(customerCopy).unwrap();
        close?.();
    };

    return (
        <MyEditModal
            className="CustomerTaxExemptionEditModal"
            model={taxExemption}
            editImmediately={true}
            fullHeight={false}
            onSave={save}
            close={close}
            isSaving={saveMutationState.isLoading}
            title={isNew ? 'New Tax Exemption' : 'Edit Tax Exemption'}
            mobileTitle="Customer"
        >
            {({ editModel, updateField, isSaving }) => {
                // check if the state already exists in the customer exemptions list
                // don't allow duplicates
                // You can't edit state in existing records, so only need to do this check for new ones
                const isStateValid =
                    isNew && customer.taxExemptions?.some(t => t.state === editModel.state);
                return (
                    <PropertyContainer>
                        <PropertyEditSelect
                            label="State"
                            value={editModel.state}
                            options={USStateDisplay.options}
                            onChange={state => updateField({ state })}
                            fullWidth
                            disabled={isSaving || !isNew}
                            validationRequired
                            validationCustom={isStateValid && 'This state is already added'}
                        />
                        <PropertyEditText
                            label="Reason"
                            value={editModel.reason}
                            onChange={reason => updateField({ reason })}
                            disabled={isSaving}
                            validationRequired
                        />
                    </PropertyContainer>
                );
            }}
        </MyEditModal>
    );
}
