import { UdfValueHashSchema } from 'models/UserDefinedField';
import z from 'zod';
import LocationType, { LocationTypeDisplay } from '../enums/LocationType';

export const LocationSchema = z
    .object({
        id: z.string(),
        canAllocate: z.boolean().optional().default(false),
        canStore: z.boolean().optional().default(false),
        isArchived: z.boolean().optional().default(false),
        locationType: z.nativeEnum(LocationType),
        name: z.string(),
        parentLocationId: z.string().optional(),
        parentLocationName: z.string().optional(),
        userDefinedFields: UdfValueHashSchema,
    })
    .transform(model => ({
        ...model,
        locationTypeDisplay: LocationTypeDisplay.display(model.locationType),
    }));

export type Location = z.infer<typeof LocationSchema>;
