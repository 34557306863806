import { dateOnly } from 'utils/dateSchemas';
import z from 'zod';
import InventoryReceiptStatus from '../enums/InventoryReceiptStatus';

export const InventoryReceiptSchema = z.object({
    id: z.string(),
    tuid: z.string(),
    status: z.nativeEnum(InventoryReceiptStatus),
    locationId: z.string(), // Parent location only
    dateReceived: dateOnly({ allowBlank: false }), // default to today, editable after creation while in draft
    notes: z.string(),
    isArchived: z.boolean(),
});

export type InventoryReceipt = z.infer<typeof InventoryReceiptSchema>;
