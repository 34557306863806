import z from 'zod';
import { InventoryLocationPathSchema } from './InventoryLocationsResult';

export const InventoryReceiptLocationSchema = z.object({
    locationId: z.string(),
    quantity: z.number(),
    context: z.object({
        location: z.object({
            canStore: z.boolean(),
            canAllocate: z.boolean(),
            isArchived: z.boolean(),
        }),
        path: z.array(InventoryLocationPathSchema),
    }),
});

export type InventoryReceiptLocation = z.infer<typeof InventoryReceiptLocationSchema>;

export const InventoryReceiptItemSchema = z.object({
    id: z.string(),
    totalQuantity: z.number(),
    locationAssignments: z.array(InventoryReceiptLocationSchema).default([]),
    context: z.object({
        inventory: z.object({
            id: z.string(),
            description: z.string(),
            hasSerialNumber: z.boolean(),
            inventoryGroupId: z.string(),
            inventoryGroupName: z.string(),
            inventoryType: z.string(),
            notes: z.string().optional(),
            partNumber: z.string(),
            supplierPartNumber: z.string(),
            isArchived: z.boolean(),
        }),
    }),
});

export type InventoryReceiptItem = z.infer<typeof InventoryReceiptItemSchema>;
