import z from 'zod';
import CustomerAccessType from '../enums/CustomerAccessType';
import CustomerHoldStatus from '../enums/CustomerHoldStatus';
import CustomerPaymentMethod from '../enums/CustomerPaymentMethod';
import CustomerType from '../enums/CustomerType';
import { CompanySchema } from './CustomerListOldData';

export const CustomerTaxExemptionSchema = z.object({
    state: z.string(),
    reason: z.string(),
});

export type CustomerTaxExemption = z.infer<typeof CustomerTaxExemptionSchema>;

export const CustomerSchema = z.object({
    id: z.number(),
    name: z.string(),
    accessType: z.nativeEnum(CustomerAccessType),
    isTaxApplicable: z.boolean(),
    isExistingCustomer: z.boolean(),
    status: z.nativeEnum(CustomerHoldStatus),
    isInternal: z.boolean(),
    ordersCount: z.number(),
    paymentMethod: z.nativeEnum(CustomerPaymentMethod),
    creditLimit: z.number().nullable(),
    creditTerms: z.string(),
    shippingDetails: z.string(),
    notes: z.string(),
    brands: z.array(
        z.object({
            id: z.number(),
            name: z.string(),
        }),
    ),
    mainCompany: CompanySchema,
    taxExemptions: z.array(CustomerTaxExemptionSchema).nullable(),
});

export type Customer = z.infer<typeof CustomerSchema>;

export function getCustomerType(customer?: Customer) {
    if (customer?.isInternal) {
        if (customer?.isTaxApplicable) {
            return CustomerType.InternalTaxable;
        }
        return CustomerType.InternalNonTaxable;
    }
    return CustomerType.External;
}
