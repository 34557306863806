import { ApiTagType, api } from 'services/api';
import z from 'zod';
import CustomerHoldStatus from './enums/CustomerHoldStatus';
import CustomerPaymentMethod from './enums/CustomerPaymentMethod';
import { Brand, BrandSchema } from './models/Brand';
import { Customer } from './models/Customer';
import { CustomerListOldDataSchema } from './models/CustomerListOldData';

const customerLoginTokenResultSchema = z.object({
    data: z.string(),
});

const brandListResultSchema = z.object({
    data: z.array(BrandSchema),
});

const customersApi = api.injectEndpoints({
    endpoints: build => ({
        customerList: build.query<Customer[], void>({
            query: () => ({
                url: `/old/manufacturer-customers`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => {
                const data = CustomerListOldDataSchema.parse(result);
                return data.data.map<Customer>(c => {
                    const cust: Customer = {
                        id: c.id,
                        name: c.company_name,
                        accessType: c.access_type,
                        isTaxApplicable: c.tax_applicable === 1,
                        isExistingCustomer: c.existing_customer === 1,
                        status: (c.manufacturer_status ||
                            CustomerHoldStatus.None) as CustomerHoldStatus,
                        isInternal: c.is_internal === 1,
                        ordersCount: c.manufacturer_orders_count,
                        paymentMethod: (c.payment_method ||
                            CustomerPaymentMethod.Default) as CustomerPaymentMethod,
                        creditLimit: c.credit_limit,
                        creditTerms: c.credit_terms ?? '',
                        shippingDetails: c.shipping_details ?? '',
                        notes: c.manufacturer_notes ?? '',
                        brands: c.brands.map(b => ({
                            id: b.id,
                            name: b.name,
                        })),
                        mainCompany: c.main_company,
                        taxExemptions: c.tax_exemptions,
                    };

                    return cust;
                });
            },
            providesTags: [ApiTagType.CustomerList],
        }),

        customerUpdate: build.mutation<void, Customer>({
            query: cust => ({
                url: `/old/manufacturer-customers/${cust.id}`,
                method: 'POST',
                data: {
                    brands: cust.brands?.map(b => ({ value: b.id })),
                    manufacturer_status:
                        cust.status === CustomerHoldStatus.None ? null : cust.status,
                    manufacturer_notes: cust.notes,
                    is_internal: cust.isInternal,
                    shipping_details: cust.shippingDetails,
                    tax_applicable: cust.isTaxApplicable,
                    payment_method: cust.paymentMethod,
                    credit_limit: cust.creditLimit,
                    credit_term: cust.creditTerms,
                    tax_exemptions: cust.taxExemptions ?? [],
                },
            }),
            invalidatesTags: [ApiTagType.CustomerList],
        }),

        /** Create a login token for the dealer portal
         * @returns a url to the portal with login token as param */
        customerLoginToken: build.query<string, number>({
            query: customerId => ({
                url: `/old/manufacturer-customers/${customerId}/login-token`,
                method: 'PUT',
            }),
            transformResponse: (result: unknown) =>
                customerLoginTokenResultSchema.parse(result).data,
        }),

        brandList: build.query<Brand[], void>({
            query: () => ({
                url: `/old/brands/select`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => brandListResultSchema.parse(result).data,
            providesTags: [ApiTagType.BrandList],
        }),
    }),
});

export default customersApi;
