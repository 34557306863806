import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import ErrorContent from 'components/ErrorContent/ErrorContent';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import { SalesItem } from 'features/sales/models/SalesItem';
import { SalesOrderWorkItem } from 'features/sales/models/SalesOrderWorkItem';
import salesApi from 'features/sales/sales.api';
import { ScheduleEntity } from 'features/schedule/models/ScheduleEntity';
import WorkItemDrawer from 'features/workOrders/components/WorkItemDrawer/WorkItemDrawer';
import WorkItemStatusBadge from 'features/workOrders/components/WorkItemStatusBadge/WorkItemStatusBadge';
import WorkItemStatus, { WorkItemStatusDisplay } from 'features/workOrders/enums/WorkItemStatus';
import { WorkOrderEntity } from 'features/workOrders/models/WorkOrderEntity';
import useUrlQueryState from 'hooks/useUrlQueryState';
import Icons from 'Icons';
import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { formatDateRelative } from 'utils/dateHelpers';
import { isEmpty } from 'utils/helpers';
import WorkItemsProgress from '../WorkItemsProgress/WorkItemsProgress';
import WorkStepsProgress from '../WorkStepsProgress/WorkStepsProgress';
import './SalesItemDrawerWorkTab.scss';

export default function SalesItemDrawerWorkTab({ model }: { model: SalesItem }) {
    const query = salesApi.useSalesItemWorkItemsQuery({
        itemId: model.id,
        manufacturerOrderId: model.manufacturerOrderId,
    });

    const [workItemId, setWorkItemId] = useUrlQueryState('workItem', '', { clearOnUnmount: true });

    const workItemTuid = useMemo(
        () => query.data?.find(item => item.id === workItemId)?.tuid,
        [query.data, workItemId],
    );

    useEffect(() => {
        const elemSelector = '.SalesItemDrawer__Drawer';
        const toggleClass = 'SalesItemDrawer__Drawer--WorkItemOpen';
        if (workItemId) {
            document.querySelector(elemSelector)?.classList.add(toggleClass);
        } else {
            document.querySelector(elemSelector)?.classList.remove(toggleClass);
        }
        // clean up func
        return () => document.querySelector(elemSelector)?.classList.remove(toggleClass);
    }, [workItemId]);

    /** close the work item drawer when clicking anywhere insude the sales item drawer
       except on another work item row
     */
    useEffect(() => {
        function handleClick(e: MouseEvent) {
            const tableElems = Array.from(
                document.querySelectorAll('.SalesItemDrawerWorkTab__WorkOrder__ItemsTable'),
            );
            const target = e.target as Element;

            // close the work items drawer when clicking anywhere insude the sales item drawer
            // except on another work item row
            const workItemDrawer = document.querySelector('.SalesItemDrawer__Drawer');
            const isDrawerClick = workItemDrawer?.contains(target);
            if (isDrawerClick) {
                const isTableClick = tableElems.some(
                    table => table === target || table?.contains(target),
                );
                if (!isTableClick) {
                    // close the drawer
                    setWorkItemId('');
                }
            }
        }

        // Bind the event listener
        document.addEventListener('click', handleClick);

        // cleanup when unmounting
        return () => document.removeEventListener('click', handleClick);
    }, [setWorkItemId]);

    type WorkOrderGroup = {
        workOrder: WorkOrderEntity;
        schedule?: ScheduleEntity;
        items: SalesOrderWorkItem[];
    };

    // Group work items by work order
    const workOrderGroups = useMemo(() => {
        const result = query.data?.reduce((arr, item) => {
            if (item.context.workOrder) {
                let group = arr.find(g => g.workOrder.id === item.context.workOrder?.id);
                if (!group) {
                    group = {
                        workOrder: item.context.workOrder,
                        schedule: item.context.schedule,
                        items: [],
                    };
                    arr.push(group);
                }
                group.items.push(item);
            }
            return arr;
        }, [] as WorkOrderGroup[]);
        return result;
    }, [query.data]);

    const columns = useMemo(
        () =>
            ColumnBuilder<SalesOrderWorkItem>()
                .column({
                    label: 'Work item',
                    key: 'tuid',
                    isSortable: true,
                    getValue: item => item.tuid,
                    width: '50%',
                })
                .column({
                    label: 'Status',
                    key: 'status',
                    isSortable: true,
                    getValue: item => item.context.workOrderItemState,
                    renderValue: (value, item) => <StatusCell item={item} />,
                })
                .build(),
        [],
    );

    const itemCount = query.data?.length ?? 0;
    const orderCount = workOrderGroups?.length ?? 0;

    const completeCount = useMemo(() => {
        if (isEmpty(query.data)) {
            return 0;
        }
        return (
            query.data?.filter(d => d.context.workOrderItemState === WorkItemStatus.Completed)
                .length ?? 0
        );
    }, [query.data]);

    const completePercent = useMemo(() => {
        if (isEmpty(query.data)) {
            return 0;
        }
        const percent = (completeCount / query.data.length) * 100;
        return Math.floor(percent);
    }, [query.data, completeCount]);

    return (
        <div className="SalesItemDrawerWorkTab">
            {query.isLoading ? (
                <div className="SalesItemDrawerWorkTab__Loading">
                    <MyLinearProgress />
                </div>
            ) : query.isError ? (
                <ErrorContent />
            ) : query.data?.length === 0 ? (
                <div className="SalesItemDrawerWorkTab__Empty">
                    No work orders created yet. Update the item status to begin scheduling work.
                </div>
            ) : (
                <>
                    <div className="SalesItemDrawerWorkTab__Header">
                        <div className="SalesItemDrawerWorkTab__Header__Counts">
                            {orderCount > 1 ? (
                                // There's always at least 2 work items
                                // shorten 'work items' to 'items'
                                <div>
                                    <strong>{itemCount} items</strong> across{' '}
                                    <strong>{orderCount} work orders</strong>
                                </div>
                            ) : (
                                // Only 1 work order - show 'work item' or 'work items' label
                                <strong>
                                    {itemCount} {itemCount === 1 ? 'work item' : 'work items'}
                                </strong>
                            )}
                            <div>
                                {completeCount} complete ({completePercent}%)
                            </div>
                        </div>
                        <div className="SalesItemDrawerWorkTab__Header__Progress">
                            {query.data && <WorkItemsProgress workItems={query.data} />}
                        </div>
                    </div>

                    {workOrderGroups?.map(group => (
                        <div
                            key={group.workOrder.id}
                            className="SalesItemDrawerWorkTab__WorkOrder"
                        >
                            <div className="SalesItemDrawerWorkTab__WorkOrder__Header">
                                <div className="SalesItemDrawerWorkTab__WorkOrder__Header__Field">
                                    <div className="label">Work order</div>
                                    <div className="value">{group.workOrder.tuid}</div>
                                </div>
                                <div className="SalesItemDrawerWorkTab__WorkOrder__Header__Field">
                                    <div className="value">
                                        <Link
                                            className="Link"
                                            to={`/schedule?date=${group.schedule?.date}&highlight=${group.workOrder.id}`}
                                            title="Scheduled dqte"
                                        >
                                            <Icons.Calendar />{' '}
                                            {formatDateRelative(group.schedule?.date)}
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <DataTable
                                // This class name is referenced in the click handler above
                                className="SalesItemDrawerWorkTab__WorkOrder__ItemsTable"
                                columns={columns}
                                data={group.items}
                                isLoading={query.isLoading}
                                isError={query.isError}
                                rowEndIcon={<Icons.ChevronRight />}
                                rowIsHighlighted={item => item.id === workItemId}
                                onRowClick={item => setWorkItemId(item.id)}
                                showHeader={false}
                            />
                        </div>
                    ))}

                    {workItemId && (
                        <WorkItemDrawer
                            workItemId={workItemId}
                            tuid={workItemTuid}
                            close={() => setWorkItemId('')}
                        />
                    )}
                </>
            )}
        </div>
    );
}

function StatusCell({ item }: { item: SalesOrderWorkItem }) {
    const showProgressBar =
        [WorkItemStatus.InProgress, WorkItemStatus.OnHold].includes(
            item.context.workOrderItemState,
        ) && item.context.workOrderItemSteps;
    return (
        <div className="SalesItemDrawerWorkTab__StatusCell">
            <WorkItemStatusBadge
                status={item.context.workOrderItemState}
                badgeType="icon"
            />
            {showProgressBar ? (
                <WorkStepsProgress
                    steps={item.context.workOrderItemSteps ?? []}
                    showPercentage
                />
            ) : (
                <div className="">
                    {WorkItemStatusDisplay.display(item.context.workOrderItemState)}
                </div>
            )}
        </div>
    );
}
