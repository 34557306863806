import { faker } from '@faker-js/faker';
import { fakeLocations } from 'features/inventory/faker/InventoryFaker';
import { fakeWorkItemDetails } from 'features/workOrders/faker/WorkOrderFaker';
import { DateTime } from 'luxon';
import { dateIsPast } from 'utils/dateHelpers';
import ShipmentStatus from '../enums/ShipmentStatus';
import { Package } from '../models/Package';
import { Shipment } from '../models/Shipment';
import { ShipmentEntitySchema } from '../models/ShipmentEntity';

faker.seed(42);
export function generateFakePackage(shipment?: Shipment): Package {
    const dimensions =
        faker.helpers.maybe(
            () =>
                `${faker.number.int({ min: 100, max: 1000 })} x ${faker.number.int({
                    min: 100,
                    max: 1000,
                })} x ${faker.number.int({ min: 100, max: 1000 })}`,
            { probability: 0.3 },
        ) ?? '';

    const weight = faker.helpers.maybe(
        () => faker.number.float({ min: 0.1, max: 5, precision: 0.01 }),
        {
            probability: 0.3,
        },
    );

    const location = faker.helpers.arrayElement(fakeLocations);

    return {
        id: faker.string.uuid(),
        tuid: `PKG${faker.number.int({ min: 1, max: 99999 }).toString().padStart(5, '0')}`,
        shipmentId: shipment?.id ?? null,
        locationId: location.id,
        dimensions,
        description: faker.commerce.productName(),
        notes: '',
        weight: weight ? `${weight}kg` : '',
        context: {
            assignedWorkOrderItems: [],
            shipment: shipment ? ShipmentEntitySchema.parse(shipment) : undefined,
        },
    };
}

export function generateFakeShipmentDetail(): Shipment {
    const name = `${faker.person.firstName()} ${faker.word.words({ count: { min: 1, max: 3 } })}`;
    const date = DateTime.now()
        .startOf('day')
        .plus({
            days: faker.number.int({ min: -7, max: 30 }),
            hours: faker.number.int({ min: 0, max: 23 }),
        })
        .toISO({ includeOffset: false }) as string;

    let status = faker.helpers.enumValue(ShipmentStatus);
    if (dateIsPast(date)) {
        status = ShipmentStatus.Completed;
    }

    const shipment: Shipment = {
        id: faker.string.uuid(),
        tuid: `SHP${faker.number.int({ min: 1, max: 99999 }).toString().padStart(5, '0')}`,
        name,
        notes: '',
        shipmentDays: 7,
        status,
        date,
        context: {
            packages: [], // populated below
            assignedWorkOrderItems: faker.helpers.arrayElements(fakeWorkItemDetails, {
                min: 0,
                max: 10,
            }),
        },
    };

    // populate packages
    shipment.context.packages = faker.helpers.multiple(() => generateFakePackage(shipment), {
        count: { min: 0, max: 5 },
    });

    return shipment;
}

export const fakeShipments = faker.helpers.multiple(generateFakeShipmentDetail, {
    count: 30,
});

export const fakePackages = [
    ...fakeShipments.flatMap(s => s.context.packages),
    ...faker.helpers.multiple(generateFakePackage, {
        count: 20,
    }),
];
fakePackages.sort((a, b) => a.tuid.localeCompare(b.tuid));
