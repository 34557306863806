import Icons from 'Icons';
import { InfoGridRow, InfoGridTable } from 'components/InfoGridTable/InfoGridTable';
import MessagePanel from 'components/MessagePanel/MessagePanel';
import { MyAutocompleteInputOption } from 'components/MyAutocompleteInput/MyAutocompleteInput';
import MyButton from 'components/MyButton/MyButton';
import MyEditModal from 'components/MyEditModal/MyEditModal';
import MyTabs from 'components/MyTabs/MyTabs';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEditAutocomplete from 'components/PropertyEditAutocomplete/PropertyEditAutocomplete';
import PropertyEditMoney from 'components/PropertyEditMoney/PropertyEditMoney';
import PropertyEditSelect from 'components/PropertyEditSelect/PropertyEditSelect';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import { selectIsUS } from 'features/auth/auth.slice';
import customersApi from 'features/customers/customers.api';
import CustomerAccessType from 'features/customers/enums/CustomerAccessType';
import CustomerHoldStatus, {
    CustomerHoldStatusDisplay,
} from 'features/customers/enums/CustomerHoldStatus';
import CustomerPaymentMethod, {
    CustomerPaymentMethodDisplay,
} from 'features/customers/enums/CustomerPaymentMethod';
import CustomerType, { CustomerTypeDisplay } from 'features/customers/enums/CustomerType';
import { useBrandOptions } from 'features/customers/hooks/useBrandOptions';
import {
    Customer,
    CustomerTaxExemption,
    getCustomerType,
} from 'features/customers/models/Customer';
import { Company } from 'features/customers/models/CustomerListOldData';
import { USStateDisplay } from 'features/customers/models/TaxExemptions';
import useUrlQueryState from 'hooks/useUrlQueryState';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { isEmpty } from 'utils/helpers';
import CustomerTaxExemptionEditModal from '../CustomerTaxExemptionEditModal/CustomerTaxExemptionEditModal';
import './CustomerDetailModal.scss';

export default function CustomerDetailModal({
    model,
    isLoading,
    isError,
    close,
}: {
    model?: Customer;
    isLoading?: boolean;
    isError?: boolean;
    close?: () => void;
}) {
    const [tab, setTab] = useUrlQueryState('tab', 'account');
    const isUS = useAppSelector(selectIsUS);

    const [saveQuery, saveQueryState] = customersApi.useCustomerUpdateMutation();
    const [dealerLoginQuery] = customersApi.useLazyCustomerLoginTokenQuery();
    const isSaving = saveQueryState.isLoading;
    const dialogManager = useDialogManager();

    const handleSave = useCallback(
        async (editModel: Customer) => {
            await saveQuery(editModel).unwrap();
        },
        [saveQuery],
    );

    const handleDealerLogin = useCallback(async () => {
        if (model) {
            const url = await dialogManager.showLoadingWhile(dealerLoginQuery(model.id).unwrap());
            window.open(url);
        }
    }, [dealerLoginQuery, dialogManager, model]);

    const canLoginToDealerPortal = model?.accessType === CustomerAccessType.DealerPortal;
    return (
        <MyEditModal
            className="CustomerDetailModal"
            title={'Customer'}
            titleContext={model?.mainCompany.name}
            mobileTitle="Customers"
            close={close}
            isLoading={isLoading}
            isError={isError}
            model={model}
            onSave={handleSave}
            isSaving={isSaving}
            withTabs={true}
            readonly={tab !== 'account'}
            headerMenuItems={useMemo(
                () => [
                    canLoginToDealerPortal && {
                        label: 'Login to dealer portal',
                        IconLeft: Icons.ExternalLink,
                        onClick: handleDealerLogin,
                    },
                ],
                [canLoginToDealerPortal, handleDealerLogin],
            )}
        >
            {({ editModel, isEditing, updateField }) => (
                <MyTabs
                    activeTab={tab}
                    setActiveTab={setTab}
                    disabled={isEditing}
                    tabs={[
                        {
                            name: 'account',
                            label: 'Account',
                            content: (
                                <AccountTab
                                    editModel={editModel}
                                    isEditing={isEditing}
                                    updateField={updateField}
                                    isSaving={isSaving}
                                />
                            ),
                        },
                        {
                            name: 'details',
                            label: 'Details',
                            content: <DetailsTab model={model?.mainCompany} />,
                        },

                        isUS && {
                            name: 'tax',
                            label: 'Tax',
                            content: <TaxTab model={model} />,
                        },
                    ]}
                />
            )}
        </MyEditModal>
    );
}

function AccountTab({
    editModel,
    isEditing = false,
    isSaving = false,
    updateField,
}: {
    editModel: Customer;
    isEditing?: boolean;
    isSaving?: boolean;
    updateField: (data: Partial<Customer>) => void;
}) {
    const brandOptions = useBrandOptions();
    const selectedBrandIds = useMemo(() => editModel.brands.map(b => b.id), [editModel.brands]);

    const handleBrandsChanged = useCallback(
        (opts: MyAutocompleteInputOption[]) => {
            const brands = opts.map(o => ({
                id: o ? parseInt(o.value, 10) : 0,
                name: o?.label ?? '',
            }));
            updateField({ brands });
        },
        [updateField],
    );

    const customerType = getCustomerType(editModel);

    const updateCustomerType = useCallback(
        (val: string) => {
            switch (val) {
                case CustomerType.External:
                    updateField({ isInternal: false, isTaxApplicable: true });
                    break;
                case CustomerType.InternalNonTaxable:
                    updateField({ isInternal: true, isTaxApplicable: false });
                    break;
                case CustomerType.InternalTaxable:
                default:
                    updateField({ isInternal: true, isTaxApplicable: true });
                    break;
            }
        },
        [updateField],
    );

    return (
        <PropertyContainer
            className="CustomerDetailModal__AccountTab"
            withInputSpacing
        >
            <PropertyEditAutocomplete
                label="Brands"
                value={selectedBrandIds}
                onChangeMultiple={handleBrandsChanged}
                options={brandOptions}
                allowMultiple={true}
                readonly={!isEditing}
            />

            <PropertyEditSelect
                label="Hold status"
                value={editModel.status}
                options={CustomerHoldStatusDisplay.options}
                onChange={val => {
                    const status = val as CustomerHoldStatus;
                    updateField({ status });
                }}
                readonly={!isEditing}
                disabled={isSaving}
            />

            <PropertyEditSelect
                label="Payment method"
                value={editModel.paymentMethod}
                options={CustomerPaymentMethodDisplay.options}
                onChange={val => {
                    const paymentMethod = val as CustomerPaymentMethod;
                    updateField({ paymentMethod });
                }}
                readonly={!isEditing}
                disabled={isSaving}
            />

            {editModel.paymentMethod === CustomerPaymentMethod.Account && (
                <PropertyContainer indent={true}>
                    <PropertyEditMoney
                        label="Credit limit"
                        value={editModel.creditLimit ?? undefined}
                        onChange={val => updateField({ creditLimit: val ?? null })}
                        allowBlank={true}
                        readonly={!isEditing}
                        disabled={isSaving}
                    />

                    <PropertyEditText
                        label="Credit terms"
                        value={editModel.creditTerms}
                        onChange={val => updateField({ creditTerms: val })}
                        multiline={true}
                        readonly={!isEditing}
                        disabled={isSaving}
                    />
                </PropertyContainer>
            )}

            <PropertyEditSelect
                label="Customer type"
                value={customerType}
                options={CustomerTypeDisplay.options}
                onChange={updateCustomerType}
                readonly={!isEditing}
                disabled={isSaving}
            />

            <PropertyEditText
                label="Shipping details"
                value={editModel.shippingDetails}
                onChange={val => updateField({ shippingDetails: val })}
                multiline={true}
                readonly={!isEditing}
                disabled={isSaving}
            />

            <PropertyEditText
                label="Notes"
                value={editModel.notes}
                onChange={val => updateField({ notes: val })}
                multiline={true}
                readonly={!isEditing}
                disabled={isSaving}
            />
        </PropertyContainer>
    );
}

function DetailsTab({ model }: { model?: Company }) {
    return (
        <div className="CustomerDetailModal__ClientInfoTab">
            <PropertyContainer>
                <PropertyDisplay
                    label="Company name"
                    value={model?.name}
                />

                <PropertyContainer
                    layout="row"
                    spreadRow
                >
                    <PropertyDisplay
                        label="Email"
                        value={
                            model?.email && (
                                <a
                                    className="Link"
                                    href={`mailto:${model?.email}`}
                                >
                                    {model?.email}
                                </a>
                            )
                        }
                    />

                    <PropertyDisplay
                        label="Phone"
                        value={
                            model?.phone && (
                                <a
                                    className="Link"
                                    href={`tel:${model?.phone}`}
                                >
                                    {model?.phone}
                                </a>
                            )
                        }
                    />
                </PropertyContainer>
                <PropertyDisplay
                    label="Address"
                    value={model?.addresses?.[0]?.full_address}
                />
            </PropertyContainer>
        </div>
    );
}

function TaxTab({ model }: { model?: Customer }) {
    const dialogManager = useDialogManager();
    const [saveMutation] = customersApi.useCustomerUpdateMutation();

    const editExemption = useCallback(
        async ({
            taxExemption,
            isEdit,
            isDelete,
        }: {
            taxExemption: CustomerTaxExemption;
            isEdit?: boolean;
            isDelete?: boolean;
        }) => {
            if (model) {
                await dialogManager.custom(CustomerTaxExemptionEditModal, {
                    taxExemption,
                    customer: model,
                    isEdit,
                    isDelete,
                });
            }
        },
        [dialogManager, model],
    );

    const addExemption = useCallback(async () => {
        editExemption({ taxExemption: { state: '', reason: '' }, isEdit: false });
    }, [editExemption]);

    const deleteExemption = useCallback(
        async (taxExemption: CustomerTaxExemption) => {
            if (model) {
                const confirmed = await dialogManager.confirm({
                    title: 'Delete Tax Exemption',
                    message: 'Are you sure you want to delete this tax exemption?',
                    acceptButtonType: 'Danger',
                });
                if (confirmed) {
                    const taxExemptions =
                        model.taxExemptions?.filter(t => t !== taxExemption) ?? [];

                    const customerCopy = { ...model, taxExemptions };
                    await saveMutation(customerCopy).unwrap();
                }
            }
        },
        [dialogManager, model, saveMutation],
    );

    const customerType = getCustomerType(model);

    return (
        <div className="CustomerDetailModal__TaxTab">
            {customerType === CustomerType.InternalNonTaxable && (
                <MessagePanel
                    className="CustomerDetailModal__TaxTab__WarningPanel"
                    messageType="warning"
                >
                    This customer is marked as <strong>Internal non-taxable</strong>. Tax exemptions
                    are not applicable.
                </MessagePanel>
            )}
            <h3 className="CustomerDetailModal__TaxTab__Title">Tax Exemptions</h3>

            {isEmpty(model?.taxExemptions) ? (
                <div className="CustomerDetailModal__TaxTab__Empty">No tax exemptions added </div>
            ) : (
                <InfoGridTable>
                    {model?.taxExemptions?.map((exemption, index) => (
                        <InfoGridRow
                            key={index}
                            label={USStateDisplay.display(exemption.state)}
                            value={
                                <div className="CustomerDetailModal__TaxTab__ExemptionValue">
                                    {exemption.reason}
                                    <MyButton
                                        className="CustomerDetailModal__TaxTab__ExemptionValue__DeleteButton"
                                        IconLeft={Icons.Close}
                                        buttonType="None"
                                        size="small"
                                        onClick={e => {
                                            e.stopPropagation();
                                            deleteExemption(exemption);
                                        }}
                                    />
                                </div>
                            }
                            onClick={() => editExemption({ taxExemption: exemption, isEdit: true })}
                        />
                    ))}
                </InfoGridTable>
            )}
            <MyButton
                className="CustomerDetailModal__TaxTab__AddExemption"
                onClick={addExemption}
                IconLeft={Icons.Plus}
                buttonType="Accent"
                // size="small"
            >
                Add exemption
            </MyButton>
        </div>
    );
}
