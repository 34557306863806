import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import MyModal from 'components/MyModal/MyModal';
import PageHeader from 'components/PageHeader/PageHeader';
import { WorkItemStepStatusDisplay } from 'features/workOrders/enums/WorkItemStepStatus';
import { WorkOrderItemStepHistory } from 'features/workstations/models/WorkOrderItemStepHistory';
import workstationsApi from 'features/workstations/workstations.api';
import React from 'react';
import { formatDateTimeRelative } from 'utils/dateHelpers';
import './WorkItemStepHistoryModal.scss';

const COLUMNS = ColumnBuilder<WorkOrderItemStepHistory>()
    .column({
        label: 'Date',
        key: 'timestamp',
        defaultSort: 'DESC',
        getValue: item => item.timestamp,
        renderValue: val => formatDateTimeRelative(val),
    })
    .column({
        label: 'Status',
        key: 'state',
        getValue: item => WorkItemStepStatusDisplay.display(item.state),
    })
    .column({
        label: 'User',
        key: 'user',
        getValue: item => `${item.context.principal.firstName} ${item.context?.principal.lastName}`,
    })
    .column({
        label: 'Workstation',
        key: 'workstation',
        emptyDash: true,
        getValue: item => item.context.workstation?.name,
    })
    .build();

export default function WorkItemStepHistoryModal({
    stepId,
    stepName,
    workItemTuid,
    workItemDescription,
    close,
}: {
    stepId: string;
    stepName: string;
    workItemTuid: string;
    workItemDescription: string | null;
    close?: () => void;
}) {
    const query = workstationsApi.useWorkItemStepHistoryQuery(stepId);
    return (
        <MyModal
            className="WorkItemStepHistoryModal"
            close={close}
            containerSelector="#modal-root-top"
            mobileTitle="Work Item"
            header={
                <PageHeader
                    title="Step History"
                    titleContext={stepName}
                    subtitle={
                        <>
                            <div className="WorkItemStepHistoryModal__Header__ItemDescription">
                                {workItemDescription}
                            </div>
                            <div className="WorkItemStepHistoryModal__Header__ItemTuid">
                                {workItemTuid}
                            </div>
                        </>
                    }
                />
            }
        >
            <DataTable
                className="WorkItemStepHistoryModal__DataTable"
                data={query.data}
                isLoading={query.isLoading}
                isError={query.isError}
                columns={COLUMNS}
                onRefresh={query.refetch}
                isRefreshing={query.isFetching}
                emptyState="No history records found"
                zebra={true}
            />
        </MyModal>
    );
}
