import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import MyModal from 'components/MyModal/MyModal';
import MySelectInput, { MySelectInputOption } from 'components/MySelectInput/MySelectInput';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import SaveStatusIndicator from 'components/SaveStatusIndicator/SaveStatusIndicator';
import WorkItemsProgress from 'features/sales/components/WorkItemsProgress/WorkItemsProgress';
import WorkItemStatusBadge from 'features/workOrders/components/WorkItemStatusBadge/WorkItemStatusBadge';
import WorkItemStatus from 'features/workOrders/enums/WorkItemStatus';
import { WorkOrderDetail } from 'features/workOrders/models/WorkOrderDetail';
import { WorkOrderItemWithState } from 'features/workOrders/models/WorkOrderItemWithState';
import workOrdersApi from 'features/workOrders/workOrders.api';
import workstationsApi from 'features/workstations/workstations.api';
import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import typescriptNaturalSort from 'typescript-natural-sort';
import { formatDateRelative } from 'utils/dateHelpers';
import WorkItemDrawer from '../WorkItemDrawer/WorkItemDrawer';
import './WorkOrderDetailModal.scss';

export default function WorkOrderDetailModal({
    workOrderId,
    tuid,
    close,
}: {
    workOrderId: string;
    tuid?: string;
    close?: () => void;
}) {
    const query = workOrdersApi.useWorkOrderDetailQuery(workOrderId);
    const model = query.data;
    const firstItem = model?.context.workOrderItems[0];

    const workstationGroupsQuery = workstationsApi.useWorkstationGroupListQuery();
    const workstationGroupOptions = useMemo(() => {
        if (!workstationGroupsQuery.data) {
            return undefined;
        }

        const opts: MySelectInputOption[] = workstationGroupsQuery.data.map(g => ({
            label: g.name,
            value: g.id,
        }));
        opts.sort((a, b) => typescriptNaturalSort(a.label, b.label));
        opts.unshift({
            label: '',
            value: '',
        });
        return opts;
    }, [workstationGroupsQuery.data]);

    const [workstationGroupAssignMutation, workstationGroupAssignMutationState] =
        workOrdersApi.useWorkOrderWorkstationGroupAssignMutation();

    const handleWorkstationGroupChange = useCallback(
        (workstationGroupId: string) => {
            if (model) {
                workstationGroupAssignMutation({
                    workOrderId: model.id,
                    workstationGroupId,
                });
            }
        },
        [model, workstationGroupAssignMutation],
    );

    const itemCount = model?.context.workOrderItems.length ?? 0;
    const completeCount =
        model?.context.workOrderItems.filter(
            i => i.context.workOrderItemState === WorkItemStatus.Completed,
        ).length ?? 0;
    const completePercent = itemCount ? Math.round((completeCount / itemCount) * 100) : 0;

    return (
        <MyModal
            className="WorkOrderDetailModal"
            close={close}
            isLoading={query.isLoading}
            isError={query.isError}
            mobileTitle="Work Orders"
            header={
                <PageHeader
                    title="Work Order"
                    titleContext={tuid}
                />
            }
        >
            {model && (
                <>
                    <PropertyContainer layout="table">
                        <PropertyDisplay
                            label="Schedule date"
                            value={
                                <Link
                                    to={`/schedule?date=${model.context.schedule.date}&highlight=${model.id}`}
                                    className="Link"
                                    onClick={close}
                                >
                                    {formatDateRelative(model.context.schedule.date)}
                                </Link>
                            }
                        />

                        <PropertyDisplay
                            label="Sales order"
                            value={
                                firstItem && (
                                    <Link
                                        to={`/sales/${firstItem.salesOrderId}`}
                                        className="Link"
                                        onClick={close}
                                    >
                                        {firstItem.salesOrderReference}
                                    </Link>
                                )
                            }
                        />

                        <PropertyDisplay
                            label="Sidemark"
                            value={model.context.workOrderItems[0]?.customerOrderSidemark}
                        />

                        <PropertyDisplay
                            label="Workstation Group"
                            value={
                                workstationGroupOptions && (
                                    <div className="WorkOrderDetailModal__WorkstationGroupWrapper">
                                        <MySelectInput
                                            options={workstationGroupOptions}
                                            value={
                                                model.context.workstationGroupAssignment?.id || ''
                                            }
                                            handleInput={handleWorkstationGroupChange}
                                        />
                                        <SaveStatusIndicator
                                            isSaving={workstationGroupAssignMutationState.isLoading}
                                            isSuccess={
                                                workstationGroupAssignMutationState.isSuccess
                                            }
                                            isError={workstationGroupAssignMutationState.isError}
                                        />
                                    </div>
                                )
                            }
                        />
                    </PropertyContainer>

                    <div className="WorkOrderDetailModal__WorkItems">
                        <div className="WorkOrderDetailModal__WorkItems__Counts">
                            <strong>
                                {itemCount} {itemCount === 1 ? 'work item' : 'work items'}
                            </strong>
                            <div>
                                {completeCount} complete ({completePercent}%)
                            </div>
                        </div>
                        <div className="WorkOrderDetailModal__WorkItems__Progress">
                            <WorkItemsProgress workItems={model.context.workOrderItems} />
                        </div>
                        <WorkItemsTable workOrder={model} />
                    </div>
                </>
            )}
        </MyModal>
    );
}

const WORK_ITEM_COLUMNS = ColumnBuilder<WorkOrderItemWithState>()
    .column({
        label: 'Work item',
        key: 'tuid',
        isSortable: true,
        getValue: item => item.tuid,
    })
    .column({
        label: 'Description',
        key: 'description',
        isSortable: true,
        getValue: item => item.description,
    })
    .column({
        label: 'Status',
        key: 'workOrderItemState',
        isSortable: true,
        width: '1px',
        whiteSpace: 'nowrap',
        getValue: item => item.context.workOrderItemState,
        renderValue: (val, item) => (
            <div className="WorkOrderDetailModal__WorkItemsTable__Status">
                <WorkItemStatusBadge status={val} />
                {item.isCancelled && <div className="cancelledBadge">Cancelled</div>}
            </div>
        ),
    })
    .build();

function WorkItemsTable({ workOrder }: { workOrder: WorkOrderDetail }) {
    const [detailsItemId, setDetailsItemId] = useState<string>();
    const detailsItem = useMemo(
        () => workOrder?.context.workOrderItems.find(i => i.id === detailsItemId),
        [detailsItemId, workOrder?.context.workOrderItems],
    );

    return (
        <>
            <DataTable
                className="WorkOrderDetailModal__WorkItemsTable"
                data={workOrder.context.workOrderItems}
                columns={WORK_ITEM_COLUMNS}
                onRowClick={item => setDetailsItemId(item.id)}
                rowIsHighlighted={item => detailsItemId === item.id}
            />

            {detailsItemId && (
                <WorkItemDrawer
                    workItemId={detailsItemId}
                    tuid={detailsItem?.tuid}
                    close={() => setDetailsItemId(undefined)}
                />
            )}
        </>
    );
}
