import { ScheduleEntitySchema } from 'features/schedule/models/ScheduleEntity';
import { ScheduleMetaEntitySchema } from 'features/schedule/models/ScheduleMetaEntity';
import WorkItemStatus from 'features/workOrders/enums/WorkItemStatus';
import z from 'zod';
import { WorkOrderEntitySchema } from './WorkOrderEntity';
import { WorkOrderItemEntitySchema } from './WorkOrderItemEntity';
import { WorkOrderItemStepSummarySchema } from './WorkOrderItemStepSummary';

export const WorkOrderItemDetailSchema = WorkOrderItemEntitySchema.extend({
    options: z.record(z.string(), z.any()),
    labels: z.record(z.string(), z.any()),
    context: z.object({
        workOrderItemState: z.nativeEnum(WorkItemStatus),
        workOrder: WorkOrderEntitySchema,
        workOrderItemSteps: z.array(WorkOrderItemStepSummarySchema),
        schedule: ScheduleEntitySchema,
        scheduleMeta: ScheduleMetaEntitySchema,
    }),
});

export type WorkOrderItemDetail = z.infer<typeof WorkOrderItemDetailSchema>;
