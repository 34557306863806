import { TextFieldProps } from '@mui/material';
import Icons from 'Icons';
import FieldValidator from 'components/FieldValidator/FieldValidator';
import MyButton from 'components/MyButton/MyButton';
import MyTextInput from 'components/MyTextInput/MyTextInput';
import React, { RefObject, useCallback, useEffect, useState } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { isEmpty } from 'utils/helpers';
import './MyNumberInput.scss';

export type MyNumberInputProps = {
    className?: string;
    value?: number;
    hint?: React.ReactNode;
    inlineHint?: React.ReactNode;
    onChange?: (val?: number) => void;
    disabled?: boolean;
    validationKey?: string;
    validationRequired?: string | boolean;
    validationRegex?: [RegExp | string, string];
    validationCustom?: string | false;
    min?: number;
    max?: number;
    withButtons?: boolean;
    allowDecimals?: boolean;
    allowBlank?: boolean;
    units?: 'percent' | 'money';
    inputRef?: RefObject<HTMLInputElement>;
} & Omit<TextFieldProps, 'onChange' | 'label'>;

export default function MyNumberInput({
    // manually specify fields that we dont want to pass in to TextField
    className,
    value,
    hint,
    inlineHint,
    onChange,
    disabled = false,
    validationKey,
    validationRequired,
    validationRegex,
    validationCustom,
    min,
    max,
    withButtons = false,
    allowDecimals,
    allowBlank,
    units,
    inputRef: forwardedRef,
    ...props
}: MyNumberInputProps) {
    const [strVal, setStrVal] = useState<string>(isEmpty(value) ? '' : `${value}`);

    useEffect(() => {
        setStrVal(isEmpty(value) ? '' : `${value}`);
    }, [value]);

    const handleInput = useCallback(
        (val: string) => {
            setStrVal(val);

            if (isEmpty(val)) {
                if (allowBlank) {
                    onChange?.(undefined);
                }
            }
            const num = allowDecimals ? parseFloat(val) : parseInt(val, 10);
            if (!Number.isNaN(num)) {
                onChange?.(num);
            }
        },
        [allowBlank, allowDecimals, onChange],
    );

    // Reset the input string value on blur
    const handleBlur = useCallback(
        event => {
            setStrVal(isEmpty(value) ? '' : `${value}`);
            props.onBlur?.(event);
        },
        [props, value],
    );

    // Reset the input string value on blur
    const increment = useCallback(
        (amt: number) => {
            let val = (value ?? 0) + amt;
            if (!isEmpty(max) && val > (max as number)) {
                val = max as number;
            }
            if (!isEmpty(min) && val < (min as number)) {
                val = min as number;
            }
            onChange?.(val);
        },
        [max, min, onChange, value],
    );

    return (
        <div
            className={coalesceClassNames(
                'MyNumberInput',
                withButtons && 'MyNumberInput--withButtons',
                className,
            )}
        >
            <FieldValidator
                value={value}
                validationKey={validationKey || (props.name as string)}
                validationRequired={validationRequired}
                validationRegex={validationRegex}
                validationCustom={validationCustom}
            >
                <div className="MyNumberInput__InputWrapper">
                    {withButtons && (
                        <MyButton
                            IconRight={Icons.Minus}
                            onClick={() => increment(-1)}
                            buttonType="Hollow"
                            disabled={(value as number) <= (min as number)}
                        />
                    )}
                    <MyTextInput
                        value={strVal}
                        type="number"
                        min={min}
                        max={max}
                        disabled={disabled}
                        handleInput={handleInput}
                        onBlur={handleBlur}
                        LeftIcon={units === 'money' ? Icons.Dollar : undefined}
                        RightIcon={units === 'percent' ? Icons.Percent : undefined}
                        inputRef={forwardedRef}
                    />
                    {withButtons && (
                        <MyButton
                            IconRight={Icons.Plus}
                            onClick={() => increment(1)}
                            buttonType="Hollow"
                            disabled={(value as number) >= (max as number)}
                        />
                    )}
                </div>
            </FieldValidator>
        </div>
    );
}
