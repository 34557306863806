import inventoryApi from '../inventory.api';
import { Location } from '../models/Location';

export function useLocationOptions({
    canStore = true,
    parentLocationId,
    locations,
}: {
    canStore?: boolean | null;
    parentLocationId?: string;
    locations?: Location[];
}): Location[] {
    const query = inventoryApi.useLocationListQuery();

    const allLocations = query.data?.data || [];
    const result = (locations || allLocations).filter(l => {
        const canStoreMatch = l.canStore === null || l.canStore === canStore;
        if (!canStoreMatch) {
            return false; // exit early
        }
        if (parentLocationId) {
            return hasAncestorLocation(l, allLocations, parentLocationId);
        }
        return true;
    });
    return result;
}

/** Recursive func which builds up the node tree from the list of all locations */
function hasAncestorLocation(
    loc: Location,
    allLocations: Location[],
    parentLocationId: string,
): boolean {
    if (!loc.parentLocationId) {
        return false;
    }
    if (loc.parentLocationId === parentLocationId) {
        return true;
    }
    const parent = allLocations.find(l => l.id === loc.parentLocationId);
    if (!parent) {
        return false;
    }
    return hasAncestorLocation(parent, allLocations, parentLocationId);
}
