import { rest } from 'msw';

import { faker } from '@faker-js/faker/locale/af_ZA';
import Env from 'config/Env';
import { fakeWorkItemDetails, fakeWorkOrders } from 'features/workOrders/faker/WorkOrderFaker';
import {
    fakeSalesOrderLineStatuses,
    fakeSalesOrders,
    fakeSalesOrderStatuses,
    getFakeSalesItemLabels,
    getFakeSalesItemOptions,
} from './faker/SalesFaker';
import { SalesOrderWorkItem } from './models/SalesOrderWorkItem';

faker.seed(42);
const BASE_URL = `${Env.API_BASE_URL}`;

export const salesHandlers = [
    // Get work items by sales order id
    rest.get(`${BASE_URL}/salesorder/:salesOrderId/workorderitems`, (req, res, ctx) => {
        // look up order lines and generate a hash of line id to work item []
        const salesOrderId = parseInt(req.params.salesOrderId as string, 10);
        const salesOrder = fakeSalesOrders.find(s => s.legacyId === salesOrderId);
        const result = {} as Record<string, SalesOrderWorkItem[]>;

        salesOrder?.context.orderLines.forEach(line => {
            faker.seed(line.id);
            result[line.id] = getWorkItems(line.id);
        });

        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),

    // Get work items by sales order item id
    rest.get(`${BASE_URL}/salesorderitems/:itemId/workorderitems`, (req, res, ctx) => {
        const lineId = parseInt(req.params.itemId as string, 10);
        faker.seed(lineId);
        const result = getWorkItems(lineId);
        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),

    // sales order list
    rest.post(`${BASE_URL}/salesorder`, (req, res, ctx) => {
        const result = {
            data: fakeSalesOrders,
            total: fakeSalesOrders.length,
        };
        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),

    // sales order detail
    rest.get(`${BASE_URL}/salesorder/:salesOrderId`, (req, res, ctx) => {
        const result = fakeSalesOrders.find(s => s.id === req.params.salesOrderId);
        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),

    // sales order statuses
    rest.get(`${BASE_URL}/old/manufacturer-order-statuses/select?all=true`, (req, res, ctx) => {
        // need to remap the schema back to old snake case format
        const data = fakeSalesOrderStatuses.map(status => ({
            id: status.id,
            name: status.name,
            background_color: status.backgroundColor,
            text_color: status.textColor,
            is_active: status.isActive ? 1 : 0,
            sort_order: status.sortOrder,
            core_status: status.coreStatus,
        }));

        return res(
            ctx.delay(500),
            ctx.status(200),
            ctx.json({
                data,
            }),
        );
    }),

    // sales order line statuses
    rest.get(
        `${BASE_URL}/old/manufacturer-order-line-statuses/select?all=true`,
        (req, res, ctx) => {
            // need to remap the schema back to old snake case format
            const data = fakeSalesOrderLineStatuses.map(status => ({
                id: status.id,
                name: status.name,
                background_color: status.backgroundColor,
                text_color: status.textColor,
                is_active: status.isActive ? 1 : 0,
                sort_order: status.sortOrder,
            }));
            return res(
                ctx.delay(500),
                ctx.status(200),
                ctx.json({
                    data,
                }),
            );
        },
    ),

    // Labels
    rest.get(`${BASE_URL}/label-config/sales-order/:orderId`, (req, res, ctx) => {
        const result = { data: getFakeSalesItemLabels() };
        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),

    // Options
    rest.get(`${BASE_URL}/old/manufacturer-order-lines/:id/inventory-options`, (req, res, ctx) => {
        const result = {
            data: getFakeSalesItemOptions(),
        };
        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),

    // Documents
    rest.get(`${BASE_URL}/old/orders/:orderId/documents`, (req, res, ctx) => {
        const result = {
            data: [],
            total: 0,
        };
        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),
    rest.get(`${BASE_URL}/old/orders/:orderId/dealer-documents`, (req, res, ctx) => {
        const result = {
            data: [],
            total: 0,
        };
        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),
];

function getWorkItems(lineId: number): SalesOrderWorkItem[] {
    faker.seed(lineId);
    const workOrders = faker.helpers.arrayElements(
        fakeWorkOrders,
        faker.number.int({ min: 0, max: 3 }),
    );
    const workItems = workOrders.reduce((arr, wo) => {
        const items = fakeWorkItemDetails.filter(wi => wi.context.workOrder.id === wo.id);
        return [...arr, ...items];
    }, [] as SalesOrderWorkItem[]);

    return workItems;
}
