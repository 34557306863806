import Icons from 'Icons';
import { InfoGridRow, InfoGridTable } from 'components/InfoGridTable/InfoGridTable';
import MyButton from 'components/MyButton/MyButton';
import MyMenuKebabButton from 'components/MyMenuKebabButton/MyMenuKebabButton';
import MyModal from 'components/MyModal/MyModal';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import accountingApi from 'features/accounting/accounting.api';
import AccountingConnectionStatus from 'features/accounting/enums/AccountingConnectionStatus';
import { AccountingConnectionTypeDisplay } from 'features/accounting/enums/AccountingConnectionType';
import {
    AccountingConnection,
    AccountingConnectionDetail,
} from 'features/accounting/models/AccountingConnection';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo, useState } from 'react';
import { formatDateRelative, formatDateTimeRelative } from 'utils/dateHelpers';
import { formatCurrency } from 'utils/helpers';
import AccountingConnectionStatusBadge from '../AccountingConnectionStatusBadge/AccountingConnectionStatusBadge';
import './AcountingConnectionDetailModal.scss';

export default function AcountingConnectionDetailModal({
    model,
    isLoading,
    isError,
    close,
}: {
    model: AccountingConnection;
    isLoading?: boolean;
    isError?: boolean;
    close?: () => void;
}) {
    const [showRawData, setShowRawData] = useState(false);

    const dialogManager = useDialogManager();
    const [retryMutation] = accountingApi.useAccountingConnectionRetryMutation();

    const retryFailed = useCallback(async () => {
        dialogManager.showLoadingWhile(retryMutation(model.id).unwrap());
    }, [dialogManager, retryMutation, model.id]);

    const menuItems = useMemo(
        () => [
            model.status === AccountingConnectionStatus.Failed && {
                label: 'Retry failed request',
                IconLeft: Icons.Refresh,
                onClick: retryFailed,
            },
            !!model.raw && {
                label: 'View raw data',
                IconLeft: Icons.Code,
                onClick: () => setShowRawData(true),
            },
        ],
        [model.raw, model.status, retryFailed],
    );

    return (
        <MyModal
            className="AcountingConnectionDetailModal"
            close={close}
            isLoading={isLoading}
            isError={isError}
            mobileTitle="Sales Order"
            header={
                <PageHeader title={AccountingConnectionTypeDisplay.display(model.type)}>
                    {menuItems.filter(Boolean).length > 0 && (
                        <MyMenuKebabButton menuItems={menuItems} />
                    )}
                </PageHeader>
            }
        >
            <PropertyContainer>
                <PropertyContainer
                    layout="row"
                    spreadRow
                >
                    <PropertyDisplay
                        label="Request date"
                        value={formatDateTimeRelative(model.createdAt)}
                    />
                    <PropertyDisplay
                        label="Request status"
                        value={<AccountingConnectionStatusBadge status={model.status} />}
                    />
                    <PropertyDisplay
                        label="External ID"
                        value={model.externalId}
                    />
                </PropertyContainer>

                <PropertyDisplay
                    label="Summary"
                    value={model.summary}
                />
            </PropertyContainer>

            <Details details={model.details} />

            {showRawData && (
                <RawDataModal
                    model={model}
                    close={() => setShowRawData(false)}
                />
            )}
        </MyModal>
    );
}

function Details({ details }: { details: AccountingConnectionDetail[] | null }) {
    return (
        <div className="AcountingConnectionDetailModal__Details">
            <h2 className="AcountingConnectionDetailModal__Details__Header">Details</h2>
            {details ? (
                <InfoGridTable>
                    {details?.map((detail, index) => (
                        <DetailRow
                            key={index}
                            detail={detail}
                        />
                    ))}
                </InfoGridTable>
            ) : (
                <div className="AcountingConnectionDetailModal__Details__Empty">
                    No details available
                </div>
            )}
        </div>
    );
}

function DetailRow({ detail }: { detail: AccountingConnectionDetail }) {
    const displayValue = useMemo(() => {
        const strVal = `${detail.value ?? ''}`;
        switch (detail.type) {
            case 'CURRENCY':
                return typeof detail.value === 'number' ? formatCurrency(detail.value) : strVal;
            case 'LINK':
                return strVal ? (
                    <a
                        className="Link"
                        href={strVal}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {strVal}
                    </a>
                ) : (
                    ''
                );
            case 'PHONE':
                return strVal ? (
                    <a
                        className="Link"
                        href={`tel:${strVal}`}
                    >
                        {strVal}
                    </a>
                ) : (
                    ''
                );
            case 'EMAIL':
                return strVal ? (
                    <a
                        className="Link"
                        href={`mailto:${strVal}`}
                    >
                        {strVal}
                    </a>
                ) : (
                    ''
                );
            case 'DATE':
                return strVal ? formatDateRelative(strVal, { alwaysDate: true }) : '';
            case 'DATETIME':
                return strVal ? formatDateTimeRelative(strVal, { alwaysDate: true }) : '';
            case 'TEXT':
            default:
                return strVal;
        }
    }, [detail]);

    return (
        <InfoGridRow
            label={detail.label}
            value={displayValue}
        />
    );
}

function RawDataModal({ model, close }: { model: AccountingConnection; close: () => void }) {
    const rawText = useMemo(
        () => (model.raw ? JSON.stringify(model.raw, null, 2) : ''),
        [model.raw],
    );
    const dialogManager = useDialogManager();

    const copyToClipboard = useCallback(() => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(rawText);
            dialogManager.toast({
                message: 'Copied to clipboard',
                icon: 'wave',
            });
        }
    }, [dialogManager, rawText]);

    return (
        <MyModal
            className="AcountingConnectionDetailModal__RawDataModal"
            close={close}
            fullHeight={true}
            mobileTitle="Accounting Connection"
            header={
                <PageHeader
                    title="Raw data"
                    titleContext={AccountingConnectionTypeDisplay.display(model.type)}
                    subtitle={<>Schema: {model.schema}</>}
                >
                    <MyButton
                        label="Copy"
                        IconLeft={Icons.Copy}
                        buttonType="Hollow"
                        onClick={copyToClipboard}
                    />
                </PageHeader>
            }
        >
            <textarea
                className="AcountingConnectionDetailModal__RawDataModal__Data"
                value={rawText}
                readOnly
            />
        </MyModal>
    );
}
