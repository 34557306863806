import { Autocomplete, TextField } from '@mui/material';
import inventoryApi, { InventorySearchParams } from 'features/inventory/inventory.api';
import { Inventory } from 'features/inventory/models/Inventory';
import React, { KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { throttle } from 'throttle-debounce';
import coalesceClassNames from 'utils/coalesceClassNames';
import { isEmpty } from 'utils/helpers';
import './InventoryReceiptInventoryInput.scss';

export default function InventoryReceiptInventoryInput({
    id,
    className,
    onChange,
    disabled = false,
    size = 'small',
}: {
    id?: string;
    className?: string;
    onChange?: (val: Inventory) => void;
    disabled?: boolean;
    size?: 'small' | 'medium';
}) {
    // Value and display options
    const [value, setValue] = useState<string>();
    const [inputValue, setInputValue] = useState('');
    const [searchValue, setSearchValue] = useState('');

    const throttledSetSearchValue = useMemo(
        () => throttle(250, (val: string) => setSearchValue(val)),
        [],
    );

    useEffect(() => {
        throttledSetSearchValue(inputValue);
    }, [throttledSetSearchValue, inputValue]);

    const inputRef = useRef();

    const searchParams: InventorySearchParams = useMemo(
        (): InventorySearchParams => ({
            criteria: {
                search: searchValue,
                inventoryGroupId: '',
            },
            paging: {
                limit: 10,
                skip: 0,
            },
        }),
        [searchValue],
    );

    const query = inventoryApi.useInventorySearchQuery(searchParams);

    // Popper state
    const [popperOpen, setPopperOpen] = useState(false);

    // Options
    const options: Inventory[] = useMemo(() => {
        // Hide the prompt if the user isn't searching
        setPopperOpen(!!inputValue);
        if (query.isFetching) {
            return [];
        }
        return inputValue ? query.data?.data ?? [] : [];
    }, [inputValue, query.data?.data, query.isFetching]);

    const handlePopperOpen = useCallback(() => {
        // Don't expand the popper if there are no options to show
        if (options.length) {
            setPopperOpen(true);
        }
    }, [options]);

    const selectedOption = useMemo(
        () => (isEmpty(value) ? null : options.find(o => o.id === value) ?? null),
        [options, value],
    );

    const handleChange = useCallback(
        (val?: Inventory | null) => {
            const matchedValue = options.find(o => o.id === val?.id);
            if (matchedValue) {
                onChange?.(matchedValue);
                // After the callback, clear the input field
                setValue(undefined);
                setInputValue('');
            }
        },
        [onChange, options],
    );

    const handleInputKey = useCallback(
        (e: KeyboardEvent) => {
            // When the user presses the return key, select the item and trigger the handler
            if (e.key === 'Enter') {
                // Use the query data before it is filtered for display in the popper -
                // this allows us to grab the prior query's response if the query is still fetching
                const matchedValue = (query.data?.data ?? []).find(
                    o => o.partNumber === inputValue,
                );
                if (matchedValue) {
                    onChange?.(matchedValue);
                }
                setInputValue('');
                setPopperOpen(false);
            }
        },
        [inputValue, onChange, query.data?.data],
    );

    return (
        <Autocomplete
            id={id}
            className={coalesceClassNames(
                'InventoryReceiptInventoryInput',
                className && `${className}__InventoryReceiptInventoryInput`,
            )}
            options={options}
            noOptionsText={inputValue ? 'No items found' : 'Search for an item'}
            disabled={disabled}
            loading={query.isFetching}
            size={size}
            open={popperOpen}
            openOnFocus={false}
            onClose={() => setPopperOpen(false)}
            onOpen={handlePopperOpen}
            inputValue={inputValue}
            value={selectedOption}
            renderInput={params => (
                <TextField
                    {...params}
                    label={''}
                    inputRef={inputRef}
                    onKeyDown={handleInputKey}
                />
            )}
            disableCloseOnSelect={false}
            selectOnFocus={true}
            getOptionLabel={option => option.description}
            onChange={(e, opt) => handleChange(opt)}
            onInputChange={(e, val) => {
                setInputValue(val);
            }}
            filterOptions={x => x}
        />
    );
}
