import AppHeader from 'components/AppHeader/AppHeader';
import MainNav from 'components/MainNav/MainNav';
import useRestoreSession from 'hooks/useRestoreSession';
import useZohoLiveChat from 'hooks/useZohoLiveChat';
import { useBreakpoints } from 'providers/Breakpoints';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import './Layout.scss';

export default function Layout() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const breakpoints = useBreakpoints();

    useZohoLiveChat();

    useEffect(() => {
        if (!breakpoints.isSmallDown) {
            setIsMenuOpen(false);
        }
    }, [breakpoints.isSmallDown]);

    const sessionRestore = useRestoreSession();

    return (
        <>
            {/* Side nav bar */}
            <MainNav
                isLoading={sessionRestore.isLoading}
                isMenuOpen={isMenuOpen}
                closeMenu={() => setIsMenuOpen(false)}
            />

            {sessionRestore.isSuccess && (
                <div className="Layout__Container">
                    <AppHeader onMenuClick={() => setIsMenuOpen(!isMenuOpen)} />
                    <main className="Layout__Main">
                        <Outlet />
                    </main>
                </div>
            )}
        </>
    );
}
