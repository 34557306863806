import MyButton from 'components/MyButton/MyButton';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import coalesceClassNames from 'utils/coalesceClassNames';
import { isEmpty } from 'utils/helpers';
import { DataTableFilter, DataTableFilterPlugin } from './DataTableFilterTypes';
import './DataTableFilters.scss';
import AutocompleteFilter, { AutocompleteFilterConfig } from './Filters/AutocompleteFilter';
import DateFilter, { DateFilterConfig } from './Filters/DateFilter';
import SearchFilter, { SearchFilterConfig } from './Filters/SearchFilter';
import SelectFilter, { SelectFilterConfig } from './Filters/SelectFilter';
import TextFilter, { TextFilterConfig } from './Filters/TextFilter';
import ToggleFilter, { ToggleFilterConfig } from './Filters/ToggleFilter';

const FilterPluginMap: { [key: string]: DataTableFilterPlugin<any> } = {
    text: TextFilter,
    search: SearchFilter,
    autocomplete: AutocompleteFilter,
    select: SelectFilter,
    date: DateFilter,
    toggle: ToggleFilter,
};

export type DataTableFilterConfig = (
    | TextFilterConfig
    | SearchFilterConfig
    | SelectFilterConfig
    | AutocompleteFilterConfig
    | DateFilterConfig
    | ToggleFilterConfig
) & {
    onChange?: (filter: DataTableFilterConfig, value: string) => void;
    applyFilter?: false | ((filter: DataTableFilterConfig, value: string, item: any) => boolean);
};

export default function DataTableFilters<T>({
    className,
    children,
    data,
    filters: filterDefs,
    onChange,
    allowReset = true,
}: {
    className?: string;
    children?: React.ReactNode;
    data?: T[];
    filters: (DataTableFilterConfig | false)[];
    onChange: (filteredData: any[] | undefined) => void;
    allowReset?: boolean;
}) {
    const [urlParams, setUrlParams] = useSearchParams({});
    const updateUrlParam = useCallback(
        (filter: DataTableFilterConfig, val?: string) => {
            if (filter.urlParam) {
                if (!val || val === filter.defaultValue) {
                    urlParams.delete(filter.urlParam);
                } else {
                    urlParams.set(filter.urlParam, val);
                }
            }
        },
        [urlParams],
    );

    const [filteredData, setFilteredData] = useState<T[] | undefined>([]);

    const filters = useMemo(
        () =>
            (filterDefs.filter(Boolean) as DataTableFilterConfig[]).map(f => {
                const urlParamValue = f.urlParam ? urlParams.get(f.urlParam) : null;
                const result: DataTableFilter<typeof f> = {
                    config: f,
                    isVisible: !!f.isSticky,
                    value: f.defaultValue || '',
                };

                // create a copy of options array for select filters
                if (result.config.type === 'select' || result.config.type === 'autocomplete') {
                    result.config.options = result.config.options ? [...result.config.options] : [];
                    // apply urlParamValue only if a valid option exists
                    // this avoids console errors about out-of-range values
                    if (
                        urlParamValue &&
                        result.config.options.some(o => o.value === urlParamValue)
                    ) {
                        result.value = urlParamValue;
                    }
                } else {
                    // apply urlParamValue
                    result.value = urlParamValue || result.value;
                }

                return result;
            }),
        [filterDefs, urlParams],
    );

    const applyFilters = useCallback(() => {
        const activeFilters = filters.filter(f => !isEmpty(f.value));

        const _filteredData: T[] | undefined = !data
            ? data
            : activeFilters.reduce(
                  (result, filter) => {
                      const func =
                          filter.config.applyFilter === false
                              ? undefined
                              : filter.config.applyFilter ??
                                FilterPluginMap[filter.config.type]?.applyFilter;
                      if (func) {
                          result = result.filter(item => func(filter.config, filter.value, item));
                      }
                      return result;
                  },
                  [...data],
              );

        setFilteredData(_filteredData);
        onChange(_filteredData);
    }, [data, filters, onChange]);

    const handleFilterChanged = useCallback(
        (filter: DataTableFilter<DataTableFilterConfig>) => {
            // update url params
            filter.config.onChange?.(filter.config, filter.value);
            updateUrlParam(filter.config, filter.value);
            setUrlParams(urlParams, { replace: true });
            applyFilters();
        },
        [applyFilters, setUrlParams, updateUrlParam, urlParams],
    );

    /** Re-run filters if data changes */
    useEffect(() => {
        applyFilters();
    }, [data, applyFilters]);

    const resetFilters = useCallback(() => {
        filters.forEach(f => {
            f.value = f.config.defaultValue || '';
            updateUrlParam(f.config, f.value);
        });
        setUrlParams(urlParams, { replace: true });
        applyFilters();
    }, [filters, setUrlParams, urlParams, applyFilters, updateUrlParam]);

    const canResetFilters = filters.some(f => (f.value ?? '') !== (f.config.defaultValue ?? ''));

    return (
        <div className={coalesceClassNames('DataTableFilters', className)}>
            <div className="DataTableFilters__Toolbar">
                <div className="DataTableFilters__Filters">
                    {filters.map((f, i) => {
                        const FilterComp = FilterPluginMap[f.config.type];

                        return FilterComp ? (
                            <FilterComp.Component
                                key={i}
                                filter={f}
                                onChange={handleFilterChanged}
                            />
                        ) : null;
                    })}
                </div>
                {children}
            </div>

            {allowReset && canResetFilters && (
                <div className="DataTableFilters__FilteredResetPanel">
                    Showing {filteredData?.length ?? 0} of {data?.length ?? 0} items
                    <MyButton
                        className="DataTableFilters__ResetButton"
                        label="Reset filters"
                        buttonType="LinkButton"
                        onClick={resetFilters}
                    />
                </div>
            )}
        </div>
    );
}
