import Icons from 'Icons';
import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import Dialog from 'components/Dialogs/Dialog';
import MyButton from 'components/MyButton/MyButton';
import MyModal from 'components/MyModal/MyModal';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import StocktakeCountStatus from 'features/stocktake/enums/StocktakeCountStatus';
import StocktakeStatus from 'features/stocktake/enums/StocktakeStatus';
import { StocktakeTypeDisplay } from 'features/stocktake/enums/StocktakeType';
import { CountSheetSummary } from 'features/stocktake/models/CountSheetSummary';
import { Stocktake } from 'features/stocktake/models/Stocktake';
import { StocktakeDiscrepancy } from 'features/stocktake/models/StocktakeDiscrepancy';
import stocktakeApi from 'features/stocktake/stocktake.api';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo, useState } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { StocktakeQuantityInput } from '../StocktakeQuantityInput/StocktakeQuantityInput';
import StocktakeStatusBadge from '../StocktakeStatusBadge/StocktakeStatusBadge';
import './StocktakeReviewModal.scss';

export default function StocktakeReviewModal({
    data,
    stocktake,
    countSheetSummaries,
    isLoading,
    isError,
    close,
}: {
    data: StocktakeDiscrepancy[];
    stocktake: Stocktake;
    countSheetSummaries?: CountSheetSummary[];
    isLoading?: boolean;
    isError?: boolean;
    close?: () => void;
}) {
    const dialogManager = useDialogManager();
    const [finaliseMutation] = stocktakeApi.useFinaliseStocktakeMutation();

    const isComplete = stocktake.status === StocktakeStatus.Complete;

    const countSheetsCompleted = useMemo(
        () =>
            countSheetSummaries?.filter(cs => cs.countStatus === StocktakeCountStatus.Complete)
                .length ?? 0,
        [countSheetSummaries],
    );
    const allCountSheetsCompleted = countSheetsCompleted === countSheetSummaries?.length;

    const editCount = useCallback(
        async (item: StocktakeDiscrepancy) => {
            const props: EditCountDialogProps = {
                item,
                stocktakeId: stocktake.id,
                countSheetId: item.countSheetId,
            };
            await dialogManager.custom(EditCountDialog, props);
        },
        [dialogManager, stocktake.id],
    );

    const finalise = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            title: 'Finalise Stocktake',
            message:
                'This will apply stock level adjustments based on the counted numbers. Are you sure?',
            acceptLabel: 'Yes, finalise now',
            acceptButtonType: 'Accent',
            cancelButtonType: 'Hollow',
        });

        if (confirm) {
            await dialogManager.showLoadingWhile(finaliseMutation(stocktake.id).unwrap());
        }
    }, [dialogManager, finaliseMutation, stocktake.id]);

    const columns = useMemo(
        () =>
            ColumnBuilder<StocktakeDiscrepancy>()
                .column({
                    label: 'Location',
                    key: 'locationName',
                    getValue: item => item.locationName,
                })
                .column({
                    label: 'Part No',
                    key: 'partNumber',
                    getValue: item => item.partNumber,
                })
                .column({
                    label: 'Description',
                    key: 'description',
                    getValue: item => item.description,
                })
                .column({
                    label: 'Expected',
                    key: 'expectedQuantity',
                    getValue: item => item.expectedQuantity,
                })
                .column({
                    label: 'Counted',
                    key: 'countedQuantity',
                    getValue: item => item.countedQuantity,
                })
                .column({
                    label: 'Difference',
                    key: 'difference',
                    render: item => <DiffDisplay value={item.difference} />,
                })
                .column({
                    label: 'Movements',
                    key: 'movementsOnHand',
                    getValue: item => item.movementsOnHand,
                })
                .column({
                    label: 'Final Adjustment',
                    key: 'adjustedDifference',
                    render: item => <DiffDisplay value={item.adjustedDifference} />,
                })
                .column(
                    !isComplete && {
                        label: '',
                        key: 'edit',
                        render: item => (
                            <MyButton
                                className="StocktakeReviewModal__RowEditButton"
                                IconLeft={Icons.Edit}
                                size="small"
                                buttonType="None"
                                title="Edit count"
                                onClick={() => editCount(item)}
                            />
                        ),
                    },
                )
                .build(),
        [editCount, isComplete],
    );

    return (
        <MyModal
            className="StocktakeReviewModal"
            mobileTitle="Stocktake"
            isLoading={isLoading}
            isError={isError}
            close={close}
        >
            <PageHeader
                className="StocktakeReviewModal__PageHeader"
                title="Stocktake Review"
                titleContext={stocktake.number}
                subtitle={`${stocktake.parentLocationName} (${StocktakeTypeDisplay.display(
                    stocktake.stocktakeType,
                )})`}
            >
                {isComplete ? (
                    <StocktakeStatusBadge
                        className="StocktakeReviewModal__PageHeader__StatusBadge"
                        status={StocktakeStatus.Complete}
                    />
                ) : (
                    <>
                        <div className="StocktakeReviewModal__Progress">
                            <span className="label">Count sheets completed</span>
                            <span className="value">
                                {countSheetsCompleted} / {countSheetSummaries?.length}
                            </span>
                        </div>
                        <MyButton
                            label="Finalise Stocktake"
                            IconLeft={Icons.Check}
                            buttonType="Accent"
                            disabled={!allCountSheetsCompleted}
                            onClick={finalise}
                        />
                    </>
                )}
            </PageHeader>

            <DataTable
                className="StocktakeReviewModal__InventoryTable"
                data={data}
                columns={columns}
            />
        </MyModal>
    );
}

function DiffDisplay({ value }: { value?: number }) {
    if (!value) {
        return null;
    }

    return (
        <span
            className={coalesceClassNames(
                'StocktakeReviewModal__DiffDisplay',
                value > 0 ? 'positive' : value < 0 ? 'negative' : '',
            )}
        >
            {value > 0 ? `+${value}` : value}
        </span>
    );
}

type EditCountDialogProps = {
    item: StocktakeDiscrepancy;
    close?: () => void;
    stocktakeId: string;
    countSheetId: string;
};

function EditCountDialog({ item, close, stocktakeId, countSheetId }: EditCountDialogProps) {
    const [countSheetUpdate, updateStatus] = stocktakeApi.useCountSheetUpdateMutation();
    const [quantity, setQuantity] = useState(item.countedQuantity);

    const save = useCallback(async () => {
        await countSheetUpdate({
            id: countSheetId,
            key: item.countSheetKey,
            stocktakeId,
            tenantInventoryId: item.tenantInventoryId,
            countedQuantity: quantity,
        }).unwrap();

        close?.();
    }, [
        close,
        countSheetId,
        countSheetUpdate,
        item.countSheetKey,
        item.tenantInventoryId,
        quantity,
        stocktakeId,
    ]);

    return (
        <Dialog
            className="StocktakeReviewModal__EditCountDialog"
            close={updateStatus.isLoading ? undefined : close}
        >
            <PageHeader
                className="StocktakeReviewModal__EditCountDialog__PageHeader"
                title="Edit counted quantity"
            />
            <div className="StocktakeReviewModal__EditCountDialog__FieldsWrapper">
                <PropertyDisplay
                    label="Part no"
                    value={item.partNumber}
                />

                <PropertyDisplay
                    label="Description"
                    value={item.description}
                />

                <PropertyDisplay
                    label="Counted quantity"
                    value={
                        <StocktakeQuantityInput
                            item={item}
                            allowBlank={false}
                            disabled={updateStatus.isLoading}
                            onChange={data => setQuantity(data.countedQuantity ?? 0)}
                        />
                    }
                />
            </div>

            <div className="StocktakeReviewModal__EditCountDialog__Footer">
                <MyButton
                    label="Cancel"
                    buttonType="Hollow"
                    onClick={close}
                    disabled={updateStatus.isLoading}
                />
                <MyButton
                    label="Save"
                    buttonType="Primary"
                    onClick={save}
                    isLoading={updateStatus.isLoading}
                />
            </div>
        </Dialog>
    );
}
