import { DateTime } from 'luxon';
import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';
import { mergeDeep } from 'utils/helpers';
import z from 'zod';
import InventoryReceiptStatus from '../enums/InventoryReceiptStatus';
import { InventoryReceiptSchema } from './InventoryReceipt';
import { InventoryReceiptItem, InventoryReceiptItemSchema } from './InventoryReceiptInventory';

export const InventoryReceiptDetailSchema = InventoryReceiptSchema.extend({
    inventory: z.array(InventoryReceiptItemSchema).default([]),
});

export type InventoryReceiptDetail = z.infer<typeof InventoryReceiptDetailSchema>;

export class InventoryReceiptDetailFactory {
    static create(data?: Partial<InventoryReceiptDetail>) {
        const defaults: InventoryReceiptDetail = {
            id: '',
            tuid: '',
            isArchived: false,
            locationId: '',
            dateReceived: DateTime.now().toFormat(`yyyy-MM-dd`) ?? '',
            notes: '',
            status: InventoryReceiptStatus.Draft,
            inventory: [],
        };

        const mergedData = mergeDeep(defaults, data);

        return InventoryReceiptDetailSchema.parse(mergedData);
    }
}

export enum InventoryReceiptError {
    InventoryArchived = 'INVENTORY_ARCHIVED',
    LocationArchived = 'LOCATION_ARCHIVED',
    LocationCannotStore = 'LOCATION_CANNOT_STORE',
    QuantityMismatch = 'QUANTITY_MISMATCH',
}

const inventoryReceiptErrorShortLabels: EnumDisplayLabels<typeof InventoryReceiptError> = {
    InventoryArchived: 'Inventory type has been archived',
    LocationArchived: 'Location has been archived',
    LocationCannotStore: 'Location cannot hold items',
    QuantityMismatch: 'Quantity mismatch',
};

export const InventoryReceiptErrorDisplay = createDisplayEnum(
    InventoryReceiptError,
    inventoryReceiptErrorShortLabels,
);

// Returns a boolean indicating whether the inventory receipt is valid
export function validateInventoryReceiptDetail(receipt: InventoryReceiptDetail) {
    return receipt.inventory.every(i => !validateInventoryReceiptInventory(i));
}

// Returns an InventoryReceiptError if validation for an inventory item fails, and null otherwise
export function validateInventoryReceiptInventory(
    item: InventoryReceiptItem,
): InventoryReceiptError | null {
    if (item.context.inventory.isArchived) {
        return InventoryReceiptError.InventoryArchived;
    }
    let locationQuantity = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const l of item.locationAssignments) {
        if (l.context.location.isArchived) {
            return InventoryReceiptError.LocationArchived;
        }
        if (!l.context.location.canStore) {
            return InventoryReceiptError.LocationCannotStore;
        }
        locationQuantity += l.quantity;
    }
    if (locationQuantity !== item.totalQuantity) {
        return InventoryReceiptError.QuantityMismatch;
    }
    return null;
}
