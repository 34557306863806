import Icons from 'Icons';
import FieldValidator from 'components/FieldValidator/FieldValidator';
import MyButton from 'components/MyButton/MyButton';
import MyTextInput from 'components/MyTextInput/MyTextInput';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEdit from 'components/PropertyEdit/PropertyEdit';
import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { formatCurrency, generateShortUuid, isEmpty, isNone } from 'utils/helpers';
import './PropertyEditNumber.scss';

export type PropertyEditNumberProps = {
    className?: string;
    value?: number;
    label: string;
    hint?: React.ReactNode;
    inlineHint?: React.ReactNode;
    onChange?: (val?: number) => void;
    readonly?: boolean;
    disabled?: boolean;
    validationRequired?: string | boolean;
    validationCustom?: string | false;
    min?: number;
    max?: number;
    withButtons?: boolean;
    allowDecimals?: boolean;
    allowBlank?: boolean;
    units?: 'percent' | 'money';
    inputRef?: RefObject<HTMLInputElement>;
};

export default function PropertyEditNumber({
    className,
    value,
    label,
    hint,
    inlineHint,
    onChange,
    readonly = false,
    disabled = false,
    validationRequired,
    validationCustom,
    min,
    max,
    withButtons = false,
    allowDecimals,
    allowBlank,
    units,
    inputRef: forwardedRef,
}: PropertyEditNumberProps) {
    const [strVal, setStrVal] = useState<string>(isEmpty(value) ? '' : `${value}`);

    useEffect(() => {
        setStrVal(isEmpty(value) ? '' : `${value}`);
    }, [value]);

    const handleInput = useCallback(
        (val: string) => {
            setStrVal(val);

            if (isEmpty(val)) {
                if (allowBlank) {
                    onChange?.(undefined);
                }
            }
            const num = allowDecimals ? parseFloat(val) : parseInt(val, 10);
            if (!Number.isNaN(num)) {
                onChange?.(num);
            }
        },
        [allowBlank, allowDecimals, onChange],
    );

    // Reset the input string value on blur
    const handleBlur = useCallback(() => {
        setStrVal(isEmpty(value) ? '' : `${value}`);
    }, [value]);

    // Reset the input string value on blur
    const increment = useCallback(
        (amt: number) => {
            let val = (value ?? 0) + amt;
            if (!isEmpty(max) && val > (max as number)) {
                val = max as number;
            }
            if (!isEmpty(min) && val < (min as number)) {
                val = min as number;
            }
            onChange?.(val);
        },
        [max, min, onChange, value],
    );

    const inputId = useRef(generateShortUuid()).current;

    const validationRequiredWithLabel =
        validationRequired === true ? `${label} is required` : validationRequired;

    if (readonly) {
        return (
            <PropertyDisplay
                className={className}
                label={label}
                hint={hint}
                inlineHint={inlineHint}
                value={
                    isNone(value) ? null : (
                        <>
                            {units === 'money' ? formatCurrency(value ?? 0) : value}
                            {units === 'percent' && '%'}
                        </>
                    )
                }
            />
        );
    }
    return (
        <PropertyEdit
            className={coalesceClassNames(
                'PropertyEditNumber',
                withButtons && 'PropertyEditNumber--withButtons',
                className,
            )}
            label={label}
            hint={hint}
            inlineHint={inlineHint}
            inputId={inputId}
        >
            <FieldValidator
                value={value}
                validationRequired={validationRequiredWithLabel}
                validationCustom={validationCustom}
            >
                <div className="PropertyEditNumber__InputWrapper">
                    {withButtons && (
                        <MyButton
                            IconRight={Icons.Minus}
                            onClick={() => increment(-1)}
                            buttonType="Hollow"
                            disabled={(value as number) <= (min as number)}
                        />
                    )}
                    <MyTextInput
                        className="PropertyEditNumber__Input"
                        id={inputId}
                        value={strVal}
                        type="number"
                        min={min}
                        max={max}
                        disabled={disabled}
                        handleInput={handleInput}
                        onBlur={handleBlur}
                        LeftIcon={units === 'money' ? Icons.Dollar : undefined}
                        RightIcon={units === 'percent' ? Icons.Percent : undefined}
                        inputRef={forwardedRef}
                    />
                    {withButtons && (
                        <MyButton
                            IconRight={Icons.Plus}
                            onClick={() => increment(1)}
                            buttonType="Hollow"
                            disabled={(value as number) >= (max as number)}
                        />
                    )}
                </div>
            </FieldValidator>
        </PropertyEdit>
    );
}
