import z from 'zod';

export enum InventoryMeasureFamily {
    Unit = 'Unit',
    Length = 'Length',
    Weight = 'Weight',
    Volume = 'Volume',
}

export const InventorySIMeasureListResultSchema = z.array(
    z.object({
        name: z.string(),
        description: z.string(),
        family: z.nativeEnum(InventoryMeasureFamily),
        symbol: z.string(),
    }),
);

export type InventorySIMeasureListResult = z.infer<typeof InventorySIMeasureListResultSchema>;
