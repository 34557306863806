import MyButton from 'components/MyButton/MyButton';
import MyTextInput from 'components/MyTextInput/MyTextInput';
import authApi from 'features/auth/auth.api';
import { useDialogManager } from 'providers/DialogManager';
import FormValidation from 'providers/FormValidation';
import React, { useCallback, useState } from 'react';
import './ForgotPasswordForm.scss';

export default function ForgotPasswordForm({ close }: { close?: () => void }) {
    const [email, setEmail] = useState('');

    const [forgotPassword, forgotPasswordStatus] = authApi.useForgotPasswordMutation();

    const dialogManager = useDialogManager();
    const submit = useCallback(async () => {
        await forgotPassword(email);
        await dialogManager.alert({
            title: 'Password reset email sent',
            message: 'Please check your inbox and follow the instructions to reset your password.',
        });
        close?.();
    }, [close, dialogManager, email, forgotPassword]);

    const isLoading = forgotPasswordStatus.isLoading;

    return (
        <FormValidation
            submit={submit}
            toastErrors={false}
        >
            {({ handleSubmit }) => (
                <form
                    noValidate
                    className="ForgotPasswordForm__Form"
                    onSubmit={handleSubmit}
                >
                    {forgotPasswordStatus.isError && (
                        <div className="ForgotPasswordForm__FormError">
                            Failed to send password reset email. Please check your address and try
                            again.
                        </div>
                    )}

                    <MyTextInput
                        name="email"
                        label="Email"
                        type="email"
                        value={email}
                        handleInput={setEmail}
                        validationRequired="Please enter your email address"
                        validationRegex={['email', 'Please enter a valid email address']}
                        fullWidth
                        disabled={isLoading}
                        autoFocus
                    />
                    <MyButton
                        buttonType="Accent"
                        size="large"
                        onClick={handleSubmit}
                        isLoading={isLoading}
                    >
                        Send reset email
                    </MyButton>
                </form>
            )}
        </FormValidation>
    );
}
