import MyEditModal from 'components/MyEditModal/MyEditModal';
import inventoryApi from 'features/inventory/inventory.api';
import { InventoryReceiptDetail } from 'features/inventory/models/InventoryReceiptDetail';
import React from 'react';
import { InventoryReceiptEditFields } from '../InventoryReceiptEditFields/InventoryReceiptEditFields';
import './InventoryReceiptEditModal.scss';

export function InventoryReceiptEditModal({
    model,
    onClose,
}: {
    model: InventoryReceiptDetail;
    onClose: () => void;
}) {
    const [saveMutation, saveMutationState] = inventoryApi.useInventoryReceiptUpdateMutation();
    const save = async (editModel: InventoryReceiptDetail) => {
        await saveMutation(editModel).unwrap();
        onClose?.();
    };

    return (
        <MyEditModal
            close={onClose}
            className="InventoryReceiptEditModal"
            title="Edit Inventory Receipt"
            titleContext={model.tuid}
            mobileTitle="Inventory Receipt"
            model={model}
            onSave={save}
            editImmediately={true}
            fullHeight={false}
        >
            {({ editModel, updateField }) => (
                <>
                    <InventoryReceiptEditFields
                        model={editModel}
                        isNew={false}
                        isSaving={saveMutationState.isLoading}
                        updateField={updateField}
                    />
                </>
            )}
        </MyEditModal>
    );
}
