import { FormControl, InputLabel, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import React, { ReactNode } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import FieldValidator from '../FieldValidator/FieldValidator';
import './MySelectInput.scss';

export type MySelectInputOption = {
    label: string;
    value: string;
    disabled?: boolean;
};

export type MySelectInputProps = {
    options?: MySelectInputOption[];
    allowBlank?: boolean;
    value?: string;
    handleInput?: (val: string, label?: string) => void;
    validationKey?: string;
    validationRequired?: string | boolean;
    validationRegex?: [RegExp | string, string];
    validationCustom?: string | false;
} & SelectProps;

export default function MySelectInput({
    value,
    handleInput,
    validationKey,
    validationRequired,
    validationCustom,
    options = [],
    allowBlank = false,
    size = 'small',
    ...props
}: MySelectInputProps) {
    const onChange = (e: SelectChangeEvent<unknown>, child: ReactNode) => {
        const val = e.target.value as string;
        const opt = options.find(o => o.value === val);
        if (handleInput) {
            handleInput(val, opt?.label);
        } else if (props.onChange) {
            props.onChange(e, child);
        }
    };

    return (
        <FieldValidator
            validationKey={validationKey}
            validationRequired={validationRequired}
            validationCustom={validationCustom}
            value={value}
        >
            {({ revealError, error }) => (
                <FormControl
                    className={coalesceClassNames(
                        'MySelectInput',
                        props.className,
                        error && 'MySelectInput--error',
                        props.label ? 'MySelectInput--with-label' : 'MySelectInput--no-label',
                    )}
                    size={size}
                >
                    <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
                    <Select
                        {...props}
                        // native={true}
                        value={value}
                        className={coalesceClassNames(
                            'MySelectInput__Select',
                            props.className && `${props.className}__Select`,
                        )}
                        size={size}
                        onChange={onChange}
                        onBlur={event => {
                            revealError();
                            props.onBlur?.(event);
                        }}
                        onClose={revealError}
                        MenuProps={{
                            className: 'MySelectInput__Menu',
                        }}
                        fullWidth={props.fullWidth}
                    >
                        {allowBlank && (
                            <MenuItem
                                key={''}
                                value={''}
                            >
                                &nbsp;
                            </MenuItem>
                        )}
                        {/* Options can either be a list of strings or objects with { label, value } */}
                        {options.map((option, index) => {
                            const label = typeof option === 'object' ? option.label : option;
                            const val = typeof option === 'object' ? option.value : option;
                            return (
                                <MenuItem
                                    key={index}
                                    value={val}
                                    disabled={option.disabled}
                                >
                                    {label || <>&nbsp;</>}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            )}
        </FieldValidator>
    );
}
