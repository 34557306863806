import Dialog from 'components/Dialogs/Dialog';
import MyButton from 'components/MyButton/MyButton';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyEditSelect from 'components/PropertyEditSelect/PropertyEditSelect';
import SalesOrderCoreStatus from 'features/sales/enums/SalesOrderCoreStatus';
import { useSalesOrderStatusOptions } from 'features/sales/hooks/useSalesOrderStatusOptions';
import { SalesOrder } from 'features/sales/models/SalesOrder';
import salesApi from 'features/sales/sales.api';
import WorkItemStatusBadge from 'features/workOrders/components/WorkItemStatusBadge/WorkItemStatusBadge';
import WorkItemStatus from 'features/workOrders/enums/WorkItemStatus';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './SalesOrderCancelDialog.scss';

export default function SalesOrderCancelDialog({
    order,
    close,
}: {
    order: SalesOrder;
    close?: () => void;
}) {
    const [statusId, setStatusId] = useState<string>();
    const [cancelMutation, cancelMutationState] = salesApi.useSalesOrderCancelMutation();
    const workItemsQuery = salesApi.useSalesOrderWorkItemsQuery(order.legacyId);
    const commit = useCallback(async () => {
        if (statusId) {
            const statusIdNum = parseInt(statusId, 10);
            await cancelMutation({ orderId: order.id, statusId: statusIdNum });
            close?.();
        }
    }, [cancelMutation, close, order, statusId]);

    const statusOptions = useSalesOrderStatusOptions();
    const cancelledStatusOptions = useMemo(
        () => statusOptions?.filter(o => o.coreStatus === SalesOrderCoreStatus.Cancelled),
        [statusOptions],
    );

    const workItemsStarted = useMemo(() => {
        let result;
        if (workItemsQuery.data) {
            result = Object.values(workItemsQuery.data)
                .flatMap(x => x)
                .filter(x => x.context.workOrderItemState !== WorkItemStatus.Unstarted);
        }
        return result;
    }, [workItemsQuery.data]);

    useEffect(() => {
        if (cancelledStatusOptions?.[0]) {
            setStatusId(cancelledStatusOptions[0].value);
        }
    }, [cancelledStatusOptions]);

    return (
        <Dialog
            className="SalesOrderCancelDialog"
            close={close}
        >
            <div className="SalesOrderCancelDialog__Header">Cancel Sales Order</div>

            {/* render any workItems that are in progress */}
            {workItemsStarted && workItemsStarted.length > 0 && (
                <div className="SalesOrderCancelDialog__WorkItems">
                    <div className="SalesOrderCancelDialog__WorkItems__Message">
                        The following work items have already been started
                    </div>
                    {workItemsStarted.map(line => (
                        <div
                            className="SalesOrderCancelDialog__WorkItems__Item"
                            key={line.tuid}
                        >
                            <WorkItemStatusBadge
                                badgeType="text"
                                status={line.context.workOrderItemState}
                            />
                            <h2 className="tuid">| {line.tuid}</h2>
                        </div>
                    ))}
                </div>
            )}
            <div className="SalesOrderCancelDialog__Message">
                If you are sure you want to cancel, please select the status to apply
            </div>

            <PropertyContainer>
                <PropertyEditSelect
                    label=""
                    value={statusId}
                    onChange={setStatusId}
                    options={cancelledStatusOptions}
                    className="SalesOrderCancelDialog__Select"
                    disabled={cancelMutationState.isLoading}
                />
            </PropertyContainer>
            <div className="PropertyContainerSpacer" />

            <div className="SalesOrderCancelDialog__Buttons">
                <MyButton
                    label="Cancel this order"
                    buttonType="Danger"
                    onClick={commit}
                    disabled={!statusId}
                    isLoading={cancelMutationState.isLoading}
                />
                <MyButton
                    label="Not now"
                    buttonType="Hollow"
                    onClick={close}
                    isLoading={cancelMutationState.isLoading}
                />
            </div>
        </Dialog>
    );
}
