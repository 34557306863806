import { Tooltip } from '@mui/material';
import Icons from 'Icons';
import MyButton, { MyButtonLink } from 'components/MyButton/MyButton';
import MyMenuButton from 'components/MyMenuButton/MyMenuButton';
import Env from 'config/Env';
import { clearAuth, selectCurrentUser } from 'features/auth/auth.slice';
import { getUserInitials } from 'features/auth/models/User';
import { useBreakpoints } from 'providers/Breakpoints';
import React, { useCallback } from 'react';
import { api } from 'services/api';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import './AppHeader.scss';

export default function AppHeader({ onMenuClick }: { onMenuClick: () => void }) {
    const user = useAppSelector(selectCurrentUser);
    const dispatch = useAppDispatch();
    const apiKey = window.ZOHO_API_KEY;

    const signOut = () => {
        dispatch(clearAuth());
        dispatch(api.util.resetApiState());
    };
    const toggleChat = useCallback(() => {
        const isOpen = document.querySelector('.zsiqfanim');
        if (isOpen) {
            window.$zoho?.salesiq.floatwindow.visible('hide');
        } else {
            window.$zoho?.salesiq.floatwindow.visible('show');
        }
    }, []);

    const breakpoints = useBreakpoints();
    return (
        <header className="AppHeader">
            <nav className="AppHeader__NavLeft">
                {breakpoints.isSmallDown && (
                    <>
                        <MyButton
                            className="AppHeader__NavLeft__MobileMenuButton"
                            IconRight={Icons.MenuLines}
                            onClick={onMenuClick}
                            buttonType="Primary"
                        />
                    </>
                )}
                {Env.MSW_ENABLED && (
                    <Tooltip
                        title={
                            <div className="AppHeader__NavLeft__MockBadge__TooltipContent">
                                <strong>Mock service worker is enabled.</strong>
                                <br /> Just think about that if you're getting errors.
                            </div>
                        }
                        placement="bottom"
                        arrow={true}
                    >
                        <div className="AppHeader__NavLeft__MockBadge">
                            <Icons.Mock /> MSW: <strong>On</strong>
                        </div>
                    </Tooltip>
                )}
            </nav>
            <nav className="AppHeader__NavRight">
                <MyMenuButton
                    className="AppHeader__NavRight__UserMenuButton"
                    buttonLabel={getUserInitials(user)}
                    buttonType="Secondary"
                    menuItems={[
                        {
                            label: 'Profile',
                            buttonType: 'None',
                            IconLeft: Icons.Person,
                            href: `/profile`,
                            LinkComponent: MyButtonLink,
                        },
                        {
                            label: 'Sign out',
                            buttonType: 'None',
                            IconLeft: Icons.Logout,
                            onClick: signOut,
                        },
                    ]}
                />
                {apiKey && (
                    <MyButton
                        className="AppHeader__NavRight__ZohoChat"
                        buttonType="None"
                        onClick={toggleChat}
                        IconLeft={Icons.ZohoChat}
                    >
                        <div>Need help?</div>
                        <div className="subtext">Live chat online now</div>
                    </MyButton>
                )}
            </nav>
        </header>
    );
}
