import z from 'zod';
import { SalesOrderLineStatusSchema } from './SalesOrderLineStatus';

export const SalesItemSchema = z.object({
    id: z.number(),
    manufacturerOrderId: z.number(),
    // for updates use this field
    // for display, use context.status
    orderLineStatusId: z.number(),
    orderWindowId: z.number(),
    orderWindowProductId: z.number(),
    inventoryId: z.number(),
    bomLines: z.string().nullable(),
    createdBy: z.number().nullable(),
    customerNotes: z.string().nullable(),
    lineCostPrice: z.number().nullable(),
    lineNumber: z.number(),
    lineSellPrice: z.number().nullable(),
    lineTax: z.number().nullable(),
    notes: z.string().nullable(),
    packingListLines: z.string().nullable(),
    pickingListLines: z.string().nullable(),
    quantity: z.number(),
    systemControlled: z.boolean(),
    unitCostPrice: z.number().nullable(),
    unitSellPrice: z.number().nullable(),
    unitTax: z.number().nullable(),
    updatedBy: z.number().nullable(),
    context: z.object({
        status: SalesOrderLineStatusSchema,
        product: z.object({
            name: z.string(),
            notes: z.string().nullable(),
            hasWidth: z.boolean(),
            hasHeight: z.boolean(),
            hasQuantity: z.boolean(),
        }),
        orderWindow: z.object({
            orderId: z.number(),
            notes: z.string().nullable(),
        }),
        orderWindowProduct: z.object({
            id: z.number(),
        }),
    }),
});
export type SalesItem = z.infer<typeof SalesItemSchema>;
