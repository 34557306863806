import Icons from 'Icons';
import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import DataTableFilters, {
    DataTableFilterConfig,
} from 'components/DataTableFilters/DataTableFilters';
import MessagePanel from 'components/MessagePanel/MessagePanel';
import MyButton, { MyButtonLink, MyButtonLinkNewTab } from 'components/MyButton/MyButton';
import MyCheckboxTree from 'components/MyCheckboxTree/MyCheckboxTree';
import MyMenuKebabButton from 'components/MyMenuKebabButton/MyMenuKebabButton';
import MyModal from 'components/MyModal/MyModal';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import Env from 'config/Env';
import { StocktakeCountStatusDisplay } from 'features/stocktake/enums/StocktakeCountStatus';
import StocktakeStatus from 'features/stocktake/enums/StocktakeStatus';
import StocktakeTargetType, {
    StocktakeTargetTypeDisplay,
} from 'features/stocktake/enums/StocktakeTargetType';
import StocktakeType, { StocktakeTypeDisplay } from 'features/stocktake/enums/StocktakeType';
import useGroupNodes from 'features/stocktake/hooks/useGroupNodes';
import useLocationNodes from 'features/stocktake/hooks/useLocationNodes';
import { CountSheetSummary } from 'features/stocktake/models/CountSheetSummary';
import { Stocktake } from 'features/stocktake/models/Stocktake';
import { StocktakeTarget } from 'features/stocktake/models/StocktakeTarget';
import stocktakeApi from 'features/stocktake/stocktake.api';
import useFileUploader from 'hooks/useFileUploader';
import { DateTime } from 'luxon';
import { UserDefinedField } from 'models/UserDefinedField';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo, useState } from 'react';
import { formatDateRelative, formatTime } from 'utils/dateHelpers';
import StocktakeEditModal from '../StocktakeEditModal/StocktakeEditModal';
import StocktakeStatusBadge from '../StocktakeStatusBadge/StocktakeStatusBadge';
import './StocktakeDetailModal.scss';

export default function StocktakeDetailModal({
    model,
    udfs,
    countSheetSummaries,
    isLoading,
    isError,
    close,
}: {
    model?: Stocktake;
    udfs?: UserDefinedField[];
    countSheetSummaries?: CountSheetSummary[];
    isLoading?: boolean;
    isError?: boolean;
    close?: () => void;
}) {
    const createdDateLocal = useMemo(
        () => (model ? DateTime.fromISO(model.createdAt, { zone: 'UTC' }).toLocal() : undefined),
        [model],
    );

    const isComplete = model?.status === StocktakeStatus.Complete;
    const [importMutation] = stocktakeApi.useStocktakeImportMutation();
    const dialogManager = useDialogManager();

    const fileUploader = useFileUploader(async file => {
        if (!model) {
            throw new Error('Stocktake model not found');
        }
        const result = await dialogManager.showLoadingWhile(
            importMutation({ file, id: model?.id }).unwrap(),
        );

        if (result.error) {
            return dialogManager.alert({
                title: 'Import Failed',
                message: (
                    <>
                        <p>
                            It looks like there is a problem with your spreadsheet, please see the
                            error below
                        </p>
                        <p className="StocktakeDetailModal__ImportErrorMessage">
                            <strong>Error:</strong> {result.error.description}
                        </p>
                    </>
                ),
            });
        }
        return result;
    });

    const [refreshStock] = stocktakeApi.useRefreshStockMutation();

    const handleRefreshStock = useCallback(async () => {
        if (model) {
            await dialogManager.showLoadingWhile(refreshStock(model.id).unwrap());
            dialogManager.alert({
                title: 'Success',
                message: 'Stock has been refreshed!',
            });
        }
    }, [dialogManager, model, refreshStock]);

    const [showEditModal, setShowEditModal] = useState(false);
    const [isTargetsExpanded, setIsTargetsExpanded] = useState(false);

    const [archiveMutation] = stocktakeApi.useStocktakeArchiveMutation();
    const handleArchive = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            message: (
                <div>
                    <p>Are you sure you want to archive this record?</p>
                    {model?.status === StocktakeStatus.InProgress && (
                        <MessagePanel messageType="warning">
                            This stocktake is still in progress
                        </MessagePanel>
                    )}
                </div>
            ),
            acceptLabel: 'Yes, archive it',
            acceptButtonType: 'Danger',
        });

        if (confirm && model) {
            await dialogManager.showLoadingWhile(archiveMutation(model.id).unwrap());
        }
    }, [archiveMutation, dialogManager, model]);

    const [unarchiveMutation] = stocktakeApi.useStocktakeUnarchiveMutation();
    const handleUnarchive = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            message: (
                <div>
                    <p>Are you sure you want to unarchive this record?</p>
                </div>
            ),
            acceptLabel: 'Yes, unarchive it',
            acceptButtonType: 'Danger',
        });

        if (confirm && model) {
            await dialogManager.showLoadingWhile(unarchiveMutation(model.id).unwrap());
        }
    }, [unarchiveMutation, dialogManager, model]);

    const menuItems = useMemo(
        () => [
            !isComplete && {
                label: 'Get all PDFs',
                IconLeft: Icons.Download,
                href: `${Env.API_BASE_URL}/stocktake/${model?.id}/countsheetpdfs`,
                LinkComponent: MyButtonLinkNewTab,
            },
            !isComplete && {
                label: 'Refresh stock',
                IconLeft: Icons.Refresh,
                onClick: handleRefreshStock,
            },
            !isComplete &&
                model?.isArchived === false &&
                model?.status !== StocktakeStatus.Complete && {
                    label: 'Edit',
                    IconLeft: Icons.Edit,
                    onClick: () => setShowEditModal(true),
                },
            {
                label: 'Export',
                IconLeft: Icons.Download,
                href: `${Env.API_BASE_URL}/stocktake/${model?.id}/export`,
                LinkComponent: MyButtonLinkNewTab,
            },
            !isComplete && {
                label: 'Import',
                IconLeft: Icons.Upload,
                onClick: fileUploader.onClick,
            },
            model?.isArchived === false && {
                label: 'Archive',
                IconLeft: Icons.Archive,
                onClick: handleArchive,
            },
            model?.isArchived === true && {
                label: 'Unarchive',
                IconLeft: Icons.Undo,
                onClick: handleUnarchive,
            },
        ],
        [
            fileUploader.onClick,
            handleArchive,
            handleRefreshStock,
            handleUnarchive,
            isComplete,
            model?.id,
            model?.isArchived,
            model?.status,
        ],
    );

    const countBy = useMemo(() => {
        if (model?.stocktakeType === StocktakeType.Rolling) {
            return model?.targets?.some(t => t.targetType === StocktakeTargetType.InventoryGroup)
                ? StocktakeTargetType.InventoryGroup
                : StocktakeTargetType.Location;
        }
        return null;
    }, [model?.stocktakeType, model?.targets]);

    return (
        <MyModal
            className="StocktakeDetailModal"
            isLoading={isLoading}
            isError={isError}
            close={close}
            mobileTitle="Stocktake"
            header={
                <PageHeader
                    className="StocktakeDetailModal__PageHeader"
                    title="Stocktake"
                    titleContext={model?.number}
                    subtitle={`Created ${formatDateRelative(createdDateLocal)} at ${formatTime(
                        createdDateLocal,
                    )}`}
                >
                    <MyButton
                        label={isComplete ? 'Review' : 'Review and finalise'}
                        IconLeft={Icons.StocktakeReview}
                        buttonType={isComplete ? 'Hollow' : 'Primary'}
                        href="review"
                        LinkComponent={MyButtonLink}
                    />
                    <MyMenuKebabButton menuItems={menuItems} />
                    <fileUploader.HiddenFileInput accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                </PageHeader>
            }
        >
            {model && (
                <>
                    <PropertyContainer>
                        <PropertyContainer
                            layout="row"
                            spreadRow
                        >
                            <PropertyDisplay
                                label="Warehouse"
                                value={model.parentLocationName}
                            />

                            <PropertyDisplay
                                label="Type"
                                value={StocktakeTypeDisplay.display(model.stocktakeType)}
                            />

                            <PropertyDisplay
                                label="Status"
                                value={
                                    <StocktakeStatusBadge
                                        status={model.status}
                                        isArchived={model.isArchived}
                                    />
                                }
                            />
                        </PropertyContainer>

                        <PropertyDisplay
                            label="Notes"
                            value={model.notes}
                        />

                        <PropertyDisplay
                            label="Count by"
                            value={StocktakeTargetTypeDisplay.display(countBy)}
                        />
                    </PropertyContainer>

                    {model.stocktakeType === StocktakeType.Rolling && (
                        <>
                            <MyButton
                                label={
                                    isTargetsExpanded
                                        ? 'Hide selected targets'
                                        : 'Show selected targets'
                                }
                                IconRight={isTargetsExpanded ? Icons.ChevronUp : Icons.ChevronDown}
                                buttonType="LinkButton"
                                onClick={() => setIsTargetsExpanded(!isTargetsExpanded)}
                            />
                            {isTargetsExpanded && (
                                <div>
                                    {countBy === StocktakeTargetType.InventoryGroup ? (
                                        <InventoryGroupTreeView targets={model.targets} />
                                    ) : (
                                        <LocationTreeView
                                            parentLocationId={model.parentLocationId}
                                            targets={model.targets}
                                        />
                                    )}
                                </div>
                            )}
                        </>
                    )}

                    <PropertyContainer>
                        {/* User defined fields */}
                        {udfs?.map(udf => (
                            <PropertyDisplay
                                key={udf.id}
                                label={udf.name}
                                hint="(user-defined)"
                                value={model.userDefinedFields[udf.uniqueKey]}
                            />
                        ))}
                    </PropertyContainer>

                    <CountSheetTable data={countSheetSummaries} />
                    {showEditModal && (
                        <StocktakeEditModal
                            model={model}
                            close={() => setShowEditModal(false)}
                        />
                    )}
                </>
            )}
        </MyModal>
    );
}

const COUNTSHEET_FILTERS: DataTableFilterConfig[] = [
    {
        label: 'Search',
        type: 'search',
        fields: ['key', 'locationName'],
    },
    {
        label: 'Status',
        type: 'select',
        field: 'countStatus',
        options: StocktakeCountStatusDisplay.options,
    },
];

const COUNTSHEET_COLUMNS = ColumnBuilder<CountSheetSummary>()
    .column({
        label: 'Count sheet',
        key: 'key',
        isSortable: true,
        getValue: item => item.key,
    })
    .column({
        label: 'Location',
        key: 'locationName',
        isSortable: true,
        getValue: item => item.locationName,
    })
    .column({
        label: 'Status',
        key: 'countStatus',
        isSortable: true,
        getValue: item => StocktakeCountStatusDisplay.display(item.countStatus),
    })
    .build();

function CountSheetTable({ data }: { data?: CountSheetSummary[] }) {
    const [filteredData, setFilteredData] = useState<CountSheetSummary[]>();

    return (
        <>
            {(data?.length ?? 0) > 0 && (
                <DataTableFilters
                    className="StocktakeDetailModal__CountSheetFilters"
                    data={data}
                    filters={COUNTSHEET_FILTERS}
                    onChange={setFilteredData}
                />
            )}
            <DataTable
                className="StocktakeDetailModal__CountSheetTable"
                data={filteredData}
                rowEndIcon={<Icons.ChevronRight />}
                rowLinkTo={cs => cs.key}
                columns={COUNTSHEET_COLUMNS}
                emptyState="No count sheets found"
            />
        </>
    );
}

function InventoryGroupTreeView({ targets }: { targets?: StocktakeTarget[] }) {
    const [groupNodes, selectedInventoryGroupIds] = useGroupNodes({
        targets,
        appendMissingIds: true,
    });

    return (
        <div className="StocktakeDetailModal__StockAllocationByInventoryGroup">
            <MyCheckboxTree
                nodes={groupNodes}
                readonly={true}
                selectedIds={selectedInventoryGroupIds}
            />
        </div>
    );
}

function LocationTreeView({
    parentLocationId,
    targets,
}: {
    parentLocationId: string;
    targets?: StocktakeTarget[];
}) {
    const [childLocationNodes, selectedLocationIds] = useLocationNodes({
        parentLocationId,
        targets,
        appendMissingIds: true,
    });

    return (
        <div className="StocktakeDetailModal__StockAllocationByLocation">
            <MyCheckboxTree
                nodes={childLocationNodes}
                readonly={true}
                selectedIds={selectedLocationIds}
            />
        </div>
    );
}
