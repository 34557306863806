import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './InventoryMeasureStatusBadge.scss';

export default function InventoryMeasureStatusBadge({
    isDisplay,
    isArchived,
    className,
}: {
    isDisplay: boolean;
    isArchived: boolean;
    className?: string;
}) {
    return (
        <>
            {isDisplay && (
                <div
                    className={coalesceClassNames(
                        'InventoryMeasureStatusBadge',
                        isDisplay && 'InventoryMeasureStatusBadge--display',
                        className,
                    )}
                >
                    Display
                </div>
            )}
            {isArchived && (
                <div
                    className={coalesceClassNames(
                        'InventoryMeasureStatusBadge',
                        isArchived && 'InventoryMeasureStatusBadge--archived',
                        className,
                    )}
                >
                    Archived
                </div>
            )}
        </>
    );
}
