import DataTable, {
    ColumnBuilder,
    DataTableColumn,
    DataTableSortDirection,
} from 'components/DataTable/DataTable';
import DataTablePaging from 'components/DataTable/DataTablePaging';
import DataTableCriteria, { CriteriaBuilder } from 'components/DataTableCriteria/DataTableCriteria';
import { InventoryReceiptStatusDisplay } from 'features/inventory/enums/InventoryReceiptStatus';
import { useWarehouseOptions } from 'features/inventory/hooks/useWarehouseOptions';
import inventoryApi, { InventoryReceiptListParams } from 'features/inventory/inventory.api';
import { selectPrimaryLocationId } from 'features/inventory/inventory.slice';
import { InventoryReceipt } from 'features/inventory/models/InventoryReceipt';
import { useDataTableDynamicQuery } from 'hooks/useDataTableDynamicQuery';
import React, { useCallback, useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { formatDateRelative } from 'utils/dateHelpers';
import InventoryReceiptStatusBadge from '../InventoryReceiptStatusBadge/InventoryReceiptStatusBadge';
import './InventoryReceiptTable.scss';

export default function InventoryReceiptTable() {
    const warehouseFilterOptions = useWarehouseOptions();
    const defaultWarehouse = useAppSelector(selectPrimaryLocationId);
    const criteriaFields = useMemo(
        () =>
            CriteriaBuilder<InventoryReceiptListParams>()
                .criteria({
                    field: 'search',
                    label: 'Search',
                    type: 'search',
                    defaultValue: '',
                })
                .criteria({
                    field: 'dateRange',
                    label: 'Date received',
                    type: 'date',
                    range: 'past',
                    defaultValue: '',
                })
                .criteria({
                    label: 'Warehouse',
                    type: 'select',
                    options: warehouseFilterOptions,
                    defaultValue: defaultWarehouse,
                    field: 'locationId',
                })
                .criteria({
                    label: 'Status',
                    type: 'select',
                    options: InventoryReceiptStatusDisplay.options,
                    defaultValue: '',
                    allowBlank: true,
                    field: 'status',
                })
                .criteria({
                    label: 'Archived',
                    type: 'toggle',
                    defaultValue: 'false',
                    urlParam: 'archived',
                    field: 'isArchived',
                })
                .build(),
        [warehouseFilterOptions, defaultWarehouse],
    );

    const { queryParams, setQueryCriteria, setQuerySort, paging, setQueryPaging } =
        useDataTableDynamicQuery<InventoryReceiptListParams>(criteriaFields);
    const query = inventoryApi.useInventoryReceiptListQuery(queryParams);

    const columns = useMemo(
        () =>
            ColumnBuilder<InventoryReceipt>()
                .column({
                    label: 'Receipt',
                    key: 'tuid',
                    getValue: item => item.tuid,
                })
                .column({
                    label: 'Date received',
                    key: 'dateReceived',
                    isSortable: true,
                    defaultSort: 'DESC',
                    getValue: item => item.dateReceived,
                    renderValue: val => (val ? formatDateRelative(val) : '-'),
                })
                .column({
                    label: 'Status',
                    key: 'status',
                    isSortable: true,
                    defaultSort: 'ASC',
                    getValue: item => item.status,
                    renderValue: (val, item) => (
                        <InventoryReceiptStatusBadge
                            status={item.status}
                            isArchived={item.isArchived}
                            size="small"
                        />
                    ),
                })
                .build(),
        [],
    );

    const handleSortChanged = useCallback(
        (col: DataTableColumn<InventoryReceipt>, direction: DataTableSortDirection) => {
            setQuerySort({
                propertyKey: col.key,
                direction,
            });
        },
        [setQuerySort],
    );

    return (
        <div className="InventoryReceiptTable">
            <div className="InventoryReceiptTable__FilterBar">
                <DataTableCriteria
                    fields={criteriaFields}
                    onChange={setQueryCriteria}
                />
            </div>

            <DataTable
                className="InventoryReceiptTable__DataTable"
                isLoading={query.isLoading}
                isError={query.isError}
                data={query.data?.data}
                onRefresh={() => {
                    query.refetch();
                }}
                rowLinkTo={item => item.id}
                zebra={true}
                useStickyHeader={true}
                useFrontEndSorting={false}
                onSortChanged={handleSortChanged}
                isRefreshing={query.isFetching}
                columns={columns}
            />

            {(query.data?.total ?? 0) > 0 && (
                <DataTablePaging
                    data={paging}
                    totalCount={query.data?.total}
                    onChange={setQueryPaging}
                />
            )}
        </div>
    );
}
