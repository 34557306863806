import { Tooltip } from '@mui/material';
import WorkItemStepStatus, {
    WorkItemStepStatusDisplay,
} from 'features/workOrders/enums/WorkItemStepStatus';
import { WorkItemStepBasic } from 'features/workOrders/models/WorkItemStepBasic';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './WorkStepsProgress.scss';

// Progress bar showing a summary of manufacturer orders and their work Item statuses
export default function WorkStepsProgress({
    steps,
    showPercentage,
}: {
    steps: WorkItemStepBasic[];
    showPercentage?: boolean;
}) {
    const totalCount = steps.length;
    const countSkipped = steps.filter(item =>
        [WorkItemStepStatus.Skipped, WorkItemStepStatus.SkippedLocked].includes(item.status),
    ).length;
    const countCompleted = steps.filter(item =>
        [WorkItemStepStatus.Completed, WorkItemStepStatus.CompletedLocked].includes(item.status),
    ).length;
    const countInProgress = steps.filter(
        item => item.status === WorkItemStepStatus.InProgress,
    ).length;
    const countOnHold = steps.filter(item => item.status === WorkItemStepStatus.OnHold).length;
    const countUnstarted = steps.filter(
        item => item.status === WorkItemStepStatus.Unstarted, // && item.context.canTransition,
    ).length;

    const percentSkipped = (countSkipped / totalCount) * 100;
    const percentCompleted = (countCompleted / totalCount) * 100;
    const percentInProgress = (countInProgress / totalCount) * 100;
    const percentOnHold = (countOnHold / totalCount) * 100;
    const percentUnstarted = (countUnstarted / totalCount) * 100;

    const countCompletedOrSkipped = countCompleted + countSkipped;
    const percentCompletedOrSkipped = (countCompletedOrSkipped / totalCount) * 100;
    return (
        <Tooltip
            title={
                <div className="WorkStepsProgress__Tooltip">
                    {showPercentage && (
                        <div className="WorkStepsProgress__Tooltip__Header">
                            {totalCount} {totalCount === 1 ? 'step' : 'steps'},{' '}
                            {countCompletedOrSkipped} complete (
                            {percentCompletedOrSkipped.toFixed(0)}%)
                        </div>
                    )}
                    <div className="WorkStepsProgress__Tooltip__Statuses">
                        <TooltipStepStatus
                            status={WorkItemStepStatus.Skipped}
                            count={countSkipped}
                        />
                        <TooltipStepStatus
                            status={WorkItemStepStatus.Completed}
                            count={countCompleted}
                        />
                        <TooltipStepStatus
                            status={WorkItemStepStatus.InProgress}
                            count={countInProgress}
                        />
                        <TooltipStepStatus
                            status={WorkItemStepStatus.OnHold}
                            count={countOnHold}
                        />
                        <TooltipStepStatus
                            status={WorkItemStepStatus.Unstarted}
                            count={countUnstarted}
                        />
                    </div>
                </div>
            }
            placement="top"
            disableInteractive={true}
            arrow
        >
            <div className="WorkStepsProgress">
                <div className="WorkStepsProgress__Bar">
                    <div
                        className={`WorkStepsProgress__Bar__Progress ${WorkItemStepStatus.Skipped}`}
                        style={{ width: `${percentSkipped}%` }}
                    />
                    <div
                        className={`WorkStepsProgress__Bar__Progress ${WorkItemStepStatus.Completed}`}
                        style={{ width: `${percentCompleted}%` }}
                    />
                    <div
                        className={`WorkStepsProgress__Bar__Progress ${WorkItemStepStatus.InProgress}`}
                        style={{ width: `${percentInProgress}%` }}
                    />
                    <div
                        className={`WorkStepsProgress__Bar__Progress ${WorkItemStepStatus.OnHold}`}
                        style={{ width: `${percentOnHold}%` }}
                    />
                    <div
                        className={`WorkStepsProgress__Bar__Progress ${WorkItemStepStatus.Unstarted}`}
                        style={{ width: `${percentUnstarted}%` }}
                    />
                </div>
                {showPercentage && (
                    <div className="WorkStepsProgress__Percent">{percentCompleted.toFixed(0)}%</div>
                )}
            </div>
        </Tooltip>
    );
}

function TooltipStepStatus({ status, count }: { status: WorkItemStepStatus; count: number }) {
    if (count === 0) {
        return null;
    }

    return (
        <div className="WorkStepsProgress__TooltipStepStatus">
            <div
                className={coalesceClassNames(
                    'WorkStepsProgress__TooltipStepStatus__Color',
                    status,
                )}
            />
            <div className="WorkStepsProgress__TooltipStepStatus__Label">
                {WorkItemStepStatusDisplay.display(status)}
            </div>
            <div className="WorkStepsProgress__TooltipStepStatus__Value">{count}</div>
        </div>
    );
}
