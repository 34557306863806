import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyEditDate from 'components/PropertyEditDate/PropertyEditDate';
import PropertyEditSelect from 'components/PropertyEditSelect/PropertyEditSelect';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import { useWarehouseOptions } from 'features/inventory/hooks/useWarehouseOptions';
import { InventoryReceipt } from 'features/inventory/models/InventoryReceipt';
import { DateTime } from 'luxon';
import React from 'react';
import './InventoryReceiptEditFields.scss';

export function InventoryReceiptEditFields({
    model,
    isNew,
    isSaving,
    updateField,
}: {
    model: InventoryReceipt;
    isNew: boolean;
    isSaving: boolean;
    updateField: (fields: Partial<InventoryReceipt>) => void;
}) {
    const warehouseOptions = useWarehouseOptions();
    return (
        <PropertyContainer>
            <PropertyContainer
                layout="row"
                spreadRow
            >
                <PropertyEditSelect
                    className="InventoryReceiptEditFields__Warehouse"
                    label="Warehouse"
                    value={model.locationId}
                    options={warehouseOptions}
                    onChange={locationId => updateField({ locationId })}
                    validationRequired={true}
                    allowBlank={false}
                    disabled={!isNew}
                />
                <PropertyEditDate
                    className="InventoryReceiptEditFields__DateReceived"
                    label="Date Received"
                    value={model.dateReceived ?? ''}
                    onChange={val =>
                        updateField({
                            dateReceived: val || undefined,
                        })
                    }
                    disabled={isSaving}
                    validationRequired={true}
                    max={DateTime.now().toFormat('yyyy-MM-dd')}
                />
            </PropertyContainer>
            <PropertyEditText
                label="Notes"
                value={model.notes}
                onChange={notes => updateField({ notes })}
                multiline={true}
                disabled={isSaving}
            />
        </PropertyContainer>
    );
}
