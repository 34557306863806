import MyTextInput from 'components/MyTextInput/MyTextInput';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEdit from 'components/PropertyEdit/PropertyEdit';
import React, { useRef } from 'react';
import { BuiltinValidators } from 'store/forms.slice';
import coalesceClassNames from 'utils/coalesceClassNames';
import { generateShortUuid } from 'utils/helpers';
import './PropertyEditText.scss';

export type PropertyEditTextProps = {
    className?: string;
    value: any;
    label: string;
    hint?: string;
    placeholder?: string;
    onChange?: (val: any) => void;
    readonly?: boolean;
    disabled?: boolean;
    autoFocus?: boolean;
    multiline?: boolean;
    maxLength?: number;
    type?: 'color' | 'text' | 'email';
    // verticalAlign?: 'middle' | 'top';
} & BuiltinValidators;

export default function PropertyEditText({
    className,
    value,
    label,
    hint,
    placeholder,
    onChange,
    readonly = false,
    disabled = false,
    multiline = false,
    maxLength,
    autoFocus,
    // verticalAlign,
    validationRequired,
    validationCustom,
    validationRegex,
}: PropertyEditTextProps) {
    const handleInput = (val: any) => {
        onChange?.(val);
    };

    const inputId = useRef(generateShortUuid()).current;

    const validationRequiredWithLabel =
        validationRequired === true ? `${label} is required` : validationRequired;

    if (readonly) {
        return (
            <PropertyDisplay
                label={label}
                hint={hint}
                value={value}
                verticalAlign={multiline ? 'top' : undefined}
            />
        );
    }

    return (
        <PropertyEdit
            className={coalesceClassNames('PropertyEditText', className)}
            label={label}
            hint={hint}
            inputId={inputId}
            verticalAlign={multiline ? 'top' : undefined}
        >
            <MyTextInput
                className="PropertyEditText__MyTextInput"
                id={inputId}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                multiline={multiline}
                fullWidth={multiline}
                maxLength={maxLength}
                handleInput={handleInput}
                autoFocus={autoFocus}
                validationRequired={validationRequiredWithLabel}
                validationRegex={validationRegex}
                validationCustom={validationCustom}
            />
        </PropertyEdit>
    );
}
