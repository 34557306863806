import Icons from 'Icons';
import WorkItemStepTransitionReason, {
    WorkItemStepTransitionReasonDisplay,
} from 'features/workOrders/enums/WorkItemStepTransitionReason';
import WorkItemStepUiState, {
    WorkItemStepUiStateDisplay,
} from 'features/workOrders/enums/WorkItemStepUiState';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './WorkItemStepStatusBadge.scss';

export default function WorkItemStepStatusBadge({
    uiState,
    lastTransitionReason,
    badgeType = 'badge',
    className,
}: {
    uiState: WorkItemStepUiState;
    lastTransitionReason: WorkItemStepTransitionReason | null;
    badgeType?: 'badge' | 'icon' | 'text' | 'title';
    className?: string;
}) {
    return (
        <div
            className={coalesceClassNames(
                'WorkItemStepStatusBadge',
                `WorkItemStepStatusBadge--${badgeType}`,
                className,
                uiState,
            )}
        >
            {badgeType !== 'badge' && <StatusIcon uiState={uiState} />}
            {badgeType !== 'icon' && (
                <div className="WorkItemStepStatusBadge__Label">
                    {/* show the reason if we have it, otherwise show the current status */}
                    {lastTransitionReason
                        ? WorkItemStepTransitionReasonDisplay.display(lastTransitionReason)
                        : WorkItemStepUiStateDisplay.display(uiState)}
                </div>
            )}
        </div>
    );
}

function StatusIcon({ uiState }: { uiState: WorkItemStepUiState }) {
    return (
        <div className={coalesceClassNames('WorkItemStepStatusBadge__Icon', uiState)}>
            {uiState === WorkItemStepUiState.Completed ? (
                <Icons.StepComplete />
            ) : uiState === WorkItemStepUiState.InProgress ? (
                <Icons.StepStart />
            ) : uiState === WorkItemStepUiState.OnHold ? (
                <Icons.StepPause />
            ) : null}
        </div>
    );
}
