import z from 'zod';
import { InventoryMeasureFamily } from './InventorySIMeasureListResult';

export const InventoryMeasureSchema = z.object({
    canPurchase: z.boolean(),
    canSell: z.boolean(),
    canConsume: z.boolean(),
    id: z.string(),
    isArchived: z.boolean(),
    isDisplayUnit: z.boolean(),
    name: z.string().nullable(),
    unitOfMeasure: z.object({
        family: z.nativeEnum(InventoryMeasureFamily),
        name: z.string(),
        symbol: z.string(),
    }),
    value: z.number(),
});

export type InventoryMeasure = z.infer<typeof InventoryMeasureSchema>;
